import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const CertificateIconOpen = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon style={{ fontSize: '20px' }} role="img" viewBox="0 0 18 18" {...props}>
      <path
        d="M12.2292 12.835L12.911 11.3892C12.9985 11.2036 13.2514 11.2036 13.339 11.3892L14.0208 12.835L15.5456 13.0682C15.7413 13.0982 15.8192 13.3499 15.6776 13.4942L14.5744 14.6188L14.8348 16.2075C14.8682 16.4115 14.6636 16.5671 14.4886 16.4708L13.125 15.7202L11.7614 16.4708C11.5864 16.5671 11.3818 16.4115 11.4152 16.2075L11.6755 14.6188L10.5724 13.4942C10.4308 13.3499 10.5087 13.0982 10.7044 13.0682L12.2292 12.835Z"
        stroke="#6F708E"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <path
        d="M15 9V4.3114C15 4.19205 14.9526 4.07759 14.8682 3.9932L12.5068 1.63181C12.4224 1.54741 12.3079 1.5 12.1886 1.5H3.45C3.20147 1.5 3 1.70147 3 1.95V16.05C3 16.2985 3.20147 16.5 3.45 16.5H8.25"
        stroke="#6F708E"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <path
        d="M12 1.5V4.05C12 4.29853 12.2015 4.5 12.45 4.5H15"
        stroke="#6F708E"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
    </SvgIcon>
  )
}
;<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>
