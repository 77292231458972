import { SvgIcon, SvgIconProps } from '@mui/material'

export const Administration = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon style={{ fontSize: '22px' }} role="img" viewBox="0 0 22 22" fill="none" {...props}>
      <path
        d="M10.5391 10.0834C12.8172 10.0834 14.6641 8.23655 14.6641 5.95837C14.6641 3.6802 12.8172 1.83337 10.5391 1.83337C8.26089 1.83337 6.41406 3.6802 6.41406 5.95837C6.41406 8.23655 8.26089 10.0834 10.5391 10.0834Z"
        stroke="#151830"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <path
        d="M2.75 20.1667V18.9063C2.75 14.0335 6.19741 10.0834 10.45 10.0834"
        stroke="#151830"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <path
        d="M15.5825 17.1111C16.4262 17.1111 17.1102 16.4271 17.1102 15.5833C17.1102 14.7396 16.4262 14.0555 15.5825 14.0555C14.7387 14.0555 14.0547 14.7396 14.0547 15.5833C14.0547 16.4271 14.7387 17.1111 15.5825 17.1111Z"
        stroke="#151830"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <path
        d="M19.7464 13.253C19.9646 12.9949 19.9486 12.6127 19.7096 12.3737L18.793 11.457C18.553 11.2171 18.169 11.2021 17.911 11.4225L17.4178 11.8441L16.8438 11.608L16.6407 10.8349C16.5657 10.5492 16.3074 10.35 16.012 10.35H15.1163C14.821 10.35 14.5628 10.549 14.4877 10.8346L14.2795 11.6258L13.74 11.8532L13.2653 11.431C13.0081 11.2022 12.6171 11.2136 12.3737 11.457L11.457 12.3737C11.2215 12.6092 11.2021 12.9847 11.4122 13.2432L11.8358 13.7646L11.612 14.3147L10.8523 14.492C10.5581 14.5607 10.35 14.8229 10.35 15.125V16.0417C10.35 16.3347 10.5461 16.5915 10.8288 16.6687L11.6243 16.8859L11.8529 17.4281L11.4315 17.9008C11.2022 18.158 11.2134 18.5493 11.457 18.793L12.3737 19.7096C12.6095 19.9454 12.9855 19.9645 13.244 19.7539L13.7649 19.3293L14.2986 19.5489L14.4948 20.3258C14.5677 20.6144 14.8273 20.8167 15.125 20.8167H16.0417C16.3392 20.8167 16.5987 20.6147 16.6718 20.3263L16.8688 19.5485L17.4247 19.3182C17.475 19.3611 17.5285 19.4072 17.5826 19.4544C17.668 19.5286 17.7474 19.5987 17.8055 19.6503L17.8745 19.7116L17.8931 19.7283L17.8979 19.7326L17.8991 19.7336L17.8993 19.7339L17.8994 19.7339C18.1564 19.9644 18.5488 19.9538 18.793 19.7096L19.7096 18.793C19.9488 18.5537 19.9646 18.171 19.7458 17.913L19.3221 17.4132L19.5498 16.8633L20.3303 16.6603C20.6167 16.5857 20.8166 16.3272 20.8166 16.0313L20.8167 15.125C20.8167 14.8277 20.615 14.5683 20.3269 14.495L19.5528 14.2982L19.3259 13.7503L19.7464 13.253Z"
        stroke="#151830"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
    </SvgIcon>
  )
}
