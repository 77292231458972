import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const SettingsIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon style={{ fontSize: '20px' }} role="img" viewBox="0 0 18 18" {...props}>
      <g clip-path="url(#clip0_1866_14288)">
        <path
          d="M9 11.25C10.2427 11.25 11.25 10.2427 11.25 9C11.25 7.75732 10.2427 6.75 9 6.75C7.75732 6.75 6.75 7.75732 6.75 9C6.75 10.2427 7.75732 11.25 9 11.25Z"
          stroke="#6F708E"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
          fill="none"
        />
        <path
          d="M14.7168 7.79655L13.8935 5.8086L15 4.5L13.5 3L12.1985 4.11221L10.1683 3.2773L9.70147 1.5H8.23575L7.76182 3.30085L5.77831 4.13697L4.5 3L3 4.5L4.09003 5.84164L3.27937 7.83472L1.5 8.25V9.75L3.30083 10.2416L4.13681 12.2248L3 13.5L4.5 15L5.84337 13.9052L7.79775 14.7092L8.25 16.5H9.75L10.2034 14.7099L12.1913 13.8866C12.5227 14.1235 13.5 15 13.5 15L15 13.5L13.8869 12.187L14.7104 10.1985L16.4999 9.7329L16.5 8.25L14.7168 7.79655Z"
          stroke="#6F708E"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
          fill="none"
        />
      </g>
      <defs>
        <clipPath id="clip0_1866_14288">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
