import { createSlice } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { Training } from './routes/Details/features/ActiveTrainings/routes/types'
import { TrainingsState } from './types'

const initialState: TrainingsState = {
  searchTerm: '',
  singleTraining: {} as Training,
  singleTrainingParticipations: [],
  singleTrainingDetails: {
    training: {},
    currentTrainingCycle: {
      id: '',
      start_date: '',
      end_date: ''
    },
    trainingCycles: [],
    statistics: {
      totalParticipations: 0,
      completedParticipations: 0,
      completionRate: 0
    },
    licenses: {
      total: 0,
      used: 0
    }
  },
  snackbar: false,
  activityLogs: null
}

export const trainingsSlice = createSlice({
  name: 'trainingsSlice',
  initialState,
  reducers: {
    setSearchTerm(state, action) {
      state.searchTerm = action.payload
    },
    setSingleTraining(state, action) {
      state.singleTraining = action.payload
    },
    setSingleTrainingParticipations(state, action) {
      state.singleTrainingParticipations = action.payload
    },
    setSingleTrainingDetails(state, action) {
      state.singleTrainingDetails = {
        ...state.singleTrainingDetails,
        training: action.payload.training,
        currentTrainingCycle: action.payload.current_training_cycle,
        trainingCycles: action.payload.training_cycles,
        statistics: {
          totalParticipations: action.payload.statistics.total_participations,
          completedParticipations: action.payload.statistics.completed_participations,
          completionRate: action.payload.statistics.completion_percentage
        },
        licenses: {
          total: action.payload.licenses.total,
          used: action.payload.licenses.used
        }
      }
    },
    setSnackBar(state, action) {
      state.snackbar = action.payload
    },
    setActivityLogs(state, action) {
      state.activityLogs = action.payload
    }
  }
})

export const trainingsReducer = persistReducer(
  {
    key: 'rtk:trainings',
    storage,
    whitelist: ['']
  },
  trainingsSlice.reducer
)
