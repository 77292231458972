import { createInstance } from 'i18next'
import Backend from 'i18next-locize-backend'
import { locizePlugin } from 'locize'
import { locizeOptions } from './i18n'

const i18n = createInstance({
  fallbackLng: 'en',
  debug: false,
  interpolation: {
    escapeValue: false
  },
  backend: locizeOptions,
  locizeLastUsed: locizeOptions
})

i18n.use(locizePlugin).use(Backend).init()

export default i18n
