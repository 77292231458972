import { createSlice } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { TenantState } from './types'

const initialState: TenantState = {
  openCreateTenantMenu: false,
  openCreateManagerMenu: false,
  openCreateNoteMenu: false,
  managerCreated: false,
  managerNotCreated: false,
  createManagerError: '',
  invitationResentError: '',
  invitationResent: false
}

export const tenantSlice = createSlice({
  name: 'tenantSlice',
  initialState,
  reducers: {
    openCreateTenantMenu(state, action) {
      state.openCreateTenantMenu = action.payload
    },
    openCreateManagerMenu(state, action) {
      state.openCreateManagerMenu = action.payload
    },
    setAccessedTenant(state, action) {
      state.tenant = action.payload
    },
    openCreateNoteMenu(state, action) {
      state.openCreateNoteMenu = action.payload
    },
    saveCreatedNoteData(state, action) {
      state.saveCreatedNoteData = action.payload
    },
    setManagerCreated(state, action) {
      state.managerCreated = action.payload
    },
    setManagerNotCreated(state, action) {
      state.managerNotCreated = action.payload.managerNotCreated
      state.createManagerError = action.payload.createManagerError
    },
    setInvitationResent(state, action) {
      state.invitationResent = action.payload.invitationResent
      state.invitationResentError = action.payload.invitationResentError
    }
  }
})

export const tenantReducer = persistReducer(
  {
    key: 'rtk:tenant',
    storage,
    whitelist: ['']
  },
  tenantSlice.reducer
)
