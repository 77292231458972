import { FullScreenProgress } from '@/common/components'
import { config as appConfig } from '@/core'
import { ErrorPageType } from '@/core/errors'
import { History, HistoryContext, LocationContext } from '@/core/history'
import { RouteResponse, RouterContext, resolveRoute } from '@/core/router'
import type { CustomError, TenantData } from '@/core/types'
import { TenantMiddleware, UserMiddleware } from '@/features/auth'
import { persistor } from '@/store'
import theme from '@/theme'
import * as amplitude from '@amplitude/analytics-browser'

import { getAmplitudeApiKey } from '@/core/utils'
import { CssBaseline, PaletteMode } from '@mui/material'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { Action, Update } from 'history'
import * as React from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { ErrorPage } from './ErrorPage'

type AppProps = {
  history: History
  tenant: TenantData | CustomError
}

export class App extends React.Component<AppProps> {
  state = {
    cxt: undefined as RouterContext | undefined,
    route: undefined as RouteResponse | undefined,
    location: this.props.history.location,
    error: undefined as ErrorPageType | undefined,
    theme: (window?.localStorage?.getItem('theme') === 'dark' ? 'dark' : 'light') as PaletteMode
  }

  rootUrl = new URL(document?.URL) as unknown as string

  static getDerivedStateFromError(error: Error): { error: Error } {
    return { error }
  }

  dispose?: () => void

  async componentDidMount(): Promise<void> {
    const { history } = this.props

    this.dispose = history.listen(this.renderPath)
    this.renderPath({ location: history.location, action: Action.Pop })

    const apiKey = getAmplitudeApiKey() || ''
    amplitude.init(apiKey, {
      defaultTracking: true
    })
  }

  componentDidUpdate(): void {
    if (this.state.route?.title) {
      self.document.title = `${this.state.route.title} - ${appConfig.app.name}`
    }
  }

  componentWillUnmount(): void {
    if (this.dispose) this.dispose()
  }

  componentDidCatch(error: Error, errorInfo: unknown): void {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo)
  }

  renderPath = async (ctx: Update): Promise<void> => {
    const { tenant } = this.props
    if (!tenant.id) {
      this.setState({ error: tenant })
      return
    }

    resolveRoute({
      path: ctx.location.pathname
    }).then((route) => {
      if (route.error) console.error(route.error)
      this.setState({ route, location: ctx.location, error: route.error })
    })
  }

  handleChangeTheme = (): void => {
    this.setState((x: { theme: PaletteMode }) => {
      const theme = x.theme === 'light' ? 'dark' : 'light'
      window.localStorage?.setItem('theme', theme)
      return { ...x, theme }
    })
  }

  render(): JSX.Element {
    const { history, tenant } = this.props
    const { route, location, error } = this.state

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme[this.state.theme]}>
          {error ? (
            <ErrorPage error={error} history={history} />
          ) : (
            <HistoryContext.Provider value={history}>
              <LocationContext.Provider value={location}>
                <PersistGate loading={<FullScreenProgress />} persistor={persistor}>
                  <CssBaseline />
                  <TenantMiddleware tenant={tenant}>
                    <UserMiddleware handleChangeTheme={this.handleChangeTheme} route={route} />
                  </TenantMiddleware>
                </PersistGate>
              </LocationContext.Provider>
            </HistoryContext.Provider>
          )}
        </ThemeProvider>
      </StyledEngineProvider>
    )
  }
}
