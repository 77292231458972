import { Route } from '@/core/router.types'

import { Training } from './Details/features/ActiveTrainings/routes/types'
import TrainingDetailPage from './Details/features/TrainingDetailPage/TrainingDetailPage'
import type Trainings from './Trainings'
import SetupTraining from './Details/features/SetupTrainings/routes/SetupTraining'

/*****
 *
 * Trainings page routes..
 */

export default [
  {
    menu: { label: 'trainings', icon: 'FavoriteTraining' },
    path: '/trainings',
    query: '',
    authorize: ['customer_success', 'customer_manager'],
    sidebarTab: true,
    component: () => import('./Trainings'),
    response: (data: any) => ({
      title: 'Trainings',
      description: 'List of trainings in the system.',
      props: data
    })
  } as unknown as Route<typeof Trainings>,
  {
    path: '/trainings/setupTraining/:courseId',
    query: '',
    authorize: ['customer_success', 'customer_manager'],
    component: () => import('./Details/features/SetupTrainings/routes/SetupTraining'),
    response: (data: any) => ({
      title: 'Setup Training',
      description: '',
      props: data
    })
  } as unknown as Route<typeof SetupTraining>,
  {
    path: '/trainings/tenants/:tenantId/:trainingCode',
    query: '',
    authorize: ['customer_success', 'customer_manager'],
    component: () => import('./Details/features/TrainingDetailPage/TrainingDetailPage'),
    response: (data: Training) => ({
      title: 'Training Detail Page',
      description: 'Use the search to start support tasks for tenants or create a new tenant.',
      props: data
    })
  } as unknown as Route<typeof TrainingDetailPage>
] as const
