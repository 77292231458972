import * as amplitude from '@amplitude/analytics-browser'
import { AppBarProps } from '@mui/material'
import { useEffect } from 'react'
import { FullScreenProgress } from '../../../../common/components/FullScreenProgress'
import { RouteResponse } from '../../../../core/router'
import { LoggedOutModal, PrivateRoute, UserData, authSlice, useAuthUser } from '../../../../features/auth'
import { useTypedDispatch } from '../../../../store'
import { AuthenticatedRoute } from '../AuthenticatedRoute/AuthenticatedRoute'

type UserMiddlewareProps = AppBarProps & {
  route: RouteResponse | undefined
  handleChangeTheme: () => void
}

export const UserMiddleware = ({ route, handleChangeTheme }: UserMiddlewareProps): JSX.Element => {
  const dispatch = useTypedDispatch()
  const user = useAuthUser()

  const setAmplitudeUser = (user: UserData) => {
    amplitude.setUserId(user.user_id)

    const identifyEvent = new amplitude.Identify()
    identifyEvent.setOnce('first_login', new Date().toISOString())
    identifyEvent.set('user_role', user.roles[0])
    amplitude.identify(identifyEvent)
  }

  useEffect(() => {
    if (user) {
      setAmplitudeUser(user)
      dispatch(authSlice.actions.setCurrentUser(user))
    }
  }, [user])

  if (!user) {
    return (
      <>
        <FullScreenProgress />
        <LoggedOutModal />
      </>
    )
  }

  return route && user && user.roles ? (
    <PrivateRoute route={route} handleChangeTheme={handleChangeTheme} tenantId={user.tenant_id} roles={user.roles} />
  ) : (
    <AuthenticatedRoute />
  )
}
