import { useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Box, IconButton, Link, Menu, MenuItem, TableCell, TableRow, Typography } from '@mui/material'

import { useNavigate } from '@/hooks'
import { Course } from '../../types'
import CustomPalette from '@/theme/CustomPalette'
import { emptyCellStyles, iconButtonStyles, menuStyles, menuSxStyles, tableCellStyles } from './CourseListItemStyle'

type DataListItemProps = {
  data: Course
  nameItem: string
  routeLabel: string
  objectType: string
  openUpdateMenu: (courseCode: string) => void
}

export const CourseListItem = ({
  data,
  nameItem,
  routeLabel,
  objectType,
  openUpdateMenu
}: DataListItemProps): JSX.Element => {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <TableRow color="inherit" sx={{ '& .MuiTableCell-root': { fontSize: '16px' } }}>
        <TableCell sx={emptyCellStyles} />
        <TableCell sx={tableCellStyles}>{data.code ?? '-'}</TableCell>
        <TableCell sx={tableCellStyles}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex'
            }}>
            <Typography>
              <Link
                key={objectType}
                color={`${CustomPalette.PilotBlue}`}
                href={`/${routeLabel}/${objectType}/`}
                onClick={navigate}>
                {data.name ?? '-'}
              </Link>
            </Typography>
          </Box>
        </TableCell>
        <TableCell sx={tableCellStyles}>{data.no_of_variants ?? '-'}</TableCell>
        <TableCell sx={tableCellStyles}>
          <IconButton
            sx={iconButtonStyles}
            id={`basic-button${objectType}`}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id={`basic-menu${objectType}`}
            anchorEl={anchorEl}
            open={open}
            onBlur={handleClose}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
            style={menuStyles}
            sx={menuSxStyles}>
            <MenuItem
              onClick={function () {
                openUpdateMenu(objectType)
              }}>
              Edit
            </MenuItem>
            <MenuItem disabled>Delete</MenuItem>
          </Menu>
        </TableCell>
        <TableCell sx={emptyCellStyles} />
      </TableRow>
    </>
  )
}
