import { CSSProperties } from 'react'
import CustomPalette from '@/theme/CustomPalette'

export const tableCellStyles = {
  borderBottom: `1px solid ${CustomPalette.SecondaryNightBlue100}`,
  paddingLeft: '0px'
}

export const emptyCellStyles = { width: 0, borderBottom: 'none' }

export const iconButtonStyles = {
  ':hover': { backgroundColor: CustomPalette.SecondaryNightBlue100 },
  ':target': { backgroundColor: CustomPalette.SecondaryNightBlue100 },
  ':visited': { backgroundColor: CustomPalette.SecondaryNightBlue100 }
}

export const menuStyles: CSSProperties = { position: 'fixed', top: '-10px', left: '-38px' }

export const menuSxStyles = {
  '& .MuiPaper-root, & .MuiPopover-paper, & .MuiMenu-paper': {
    width: '121px',
    height: '100px',
    border: '1px solid rgb(196, 196, 229)',
    backgroundColor: 'rgb(248, 248, 250)',
    borderRadius: '10px',
    boxShadow: 'none'
  },
  '& .MuiList-root, & .MuiMenu-list': { padding: '14px 0' }
}
