import { PreloadedState, combineReducers, configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { Reducer } from 'redux'
import { FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist'

import { administrationReducer, administrationSlice } from '../features/administration/slice'
import { authReducer, authSlice } from '../features/auth'
import { courseReducer, courseSlice } from '../features/course'
import { participantReducer, participantsSlice } from '../features/participants/slice'
import { tenantReducer, tenantSlice } from '../features/tenant'
import { authApi, AUTH_API_REDUCER_KEY } from '../services/api/auth/api'
import { tenantApi, TENANT_API_REDUCER_KEY } from '../services/api/tenant/api'
import { RESET_STATE_ACTION_TYPE } from '../store/actions/resetState'
import { unauthenticatedMiddleware } from '../store/middlewares/unauthenticatedMiddleware'
import { trainingsReducer, trainingsSlice } from '../features/trainings/slice'

const reducers = {
  [authSlice.name]: authReducer,
  [tenantSlice.name]: tenantReducer,
  [courseSlice.name]: courseReducer,
  [administrationSlice.name]: administrationReducer,
  [participantsSlice.name]: participantReducer,
  [trainingsSlice.name]: trainingsReducer,
  [AUTH_API_REDUCER_KEY]: authApi.reducer,
  [TENANT_API_REDUCER_KEY]: tenantApi.reducer
}

const combinedReducer = combineReducers<typeof reducers>(reducers)

export const rootReducer: Reducer<RootState> = (state, action) => {
  if (action.type === RESET_STATE_ACTION_TYPE) {
    state = {} as RootState
  }

  return combinedReducer(state, action)
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat([authApi.middleware, tenantApi.middleware])
})

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState
  })
}

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type AppStore = ReturnType<typeof configureStore>
export type RootState = ReturnType<typeof combinedReducer>
export const useTypedDispatch = () => useDispatch<AppDispatch>()
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
