import * as React from 'react'
import { ComponentClass, ComponentType, lazy } from 'react'
import { UserDto } from '@/services/api/tenant/user'

type View = {
  views?: TenantView[]
  handleParticipantAdded?: (participant: UserDto) => void
  action?: () => void
}

export type TenantView<Component extends ComponentType<unknown> | ComponentClass<unknown> = ComponentType<unknown>> = {
  index?: string
  component: () => Promise<{ default: Component }>
  props?: { title?: string }
}

const importView = (components: () => Promise<{ default: React.ComponentType<unknown> }>) =>
  lazy(async () => (await components?.().then((x) => x)) || import(`@/common/ErrorPage`))

export const useLoadViews = async ({ views, action, ...rest }: View): Promise<JSX.Element[]> => {
  const componentPromises = views?.map(async ({ index, props, component }) => {
    const View = importView(component)

    const viewProps = {
      action: action,
      ...props,
      ...rest
    }

    return <View {...viewProps} key={index} />
  })

  const loadedViews = componentPromises ? await Promise.all(componentPromises) : []

  return loadedViews.filter((menu) => (arr: unknown[]) => [...arr, menu])
}
