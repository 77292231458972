import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const AvatarIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon style={{ fontSize: '28px' }} role="img" viewBox="0 0 32 33" {...props}>
      <rect y="0.5" width="32" height="32" rx="16" fill="#D7D9F7" />
      <path
        d="M21.4136 20.4294C21.1189 19.7312 20.6911 19.097 20.1542 18.5622C19.619 18.0258 18.9849 17.5981 18.2871 17.3028C18.2808 17.2997 18.2746 17.2981 18.2683 17.295C19.2417 16.5919 19.8746 15.4465 19.8746 14.1544C19.8746 12.0137 18.1402 10.2794 15.9996 10.2794C13.8589 10.2794 12.1246 12.0137 12.1246 14.1544C12.1246 15.4465 12.7574 16.5919 13.7308 17.2965C13.7246 17.2997 13.7183 17.3012 13.7121 17.3044C13.0121 17.5997 12.3839 18.0231 11.8449 18.5637C11.3085 19.099 10.8808 19.7331 10.5855 20.4309C10.2954 21.1141 10.1389 21.8466 10.1246 22.5887C10.1241 22.6054 10.1271 22.622 10.1332 22.6375C10.1392 22.6531 10.1484 22.6672 10.16 22.6792C10.1717 22.6911 10.1856 22.7006 10.201 22.7071C10.2164 22.7135 10.2329 22.7169 10.2496 22.7169H11.1871C11.2558 22.7169 11.3105 22.6622 11.3121 22.595C11.3433 21.3887 11.8277 20.259 12.6839 19.4028C13.5699 18.5169 14.7464 18.0294 15.9996 18.0294C17.2527 18.0294 18.4292 18.5169 19.3152 19.4028C20.1714 20.259 20.6558 21.3887 20.6871 22.595C20.6886 22.6637 20.7433 22.7169 20.8121 22.7169H21.7496C21.7662 22.7169 21.7827 22.7135 21.7981 22.7071C21.8135 22.7006 21.8274 22.6911 21.8391 22.6792C21.8507 22.6672 21.8599 22.6531 21.8659 22.6375C21.872 22.622 21.875 22.6054 21.8746 22.5887C21.8589 21.8419 21.7042 21.1153 21.4136 20.4294V20.4294ZM15.9996 16.8419C15.2824 16.8419 14.6074 16.5622 14.0996 16.0544C13.5917 15.5465 13.3121 14.8715 13.3121 14.1544C13.3121 13.4372 13.5917 12.7622 14.0996 12.2544C14.6074 11.7465 15.2824 11.4669 15.9996 11.4669C16.7167 11.4669 17.3917 11.7465 17.8996 12.2544C18.4074 12.7622 18.6871 13.4372 18.6871 14.1544C18.6871 14.8715 18.4074 15.5465 17.8996 16.0544C17.3917 16.5622 16.7167 16.8419 15.9996 16.8419Z"
        fill="#5664E5"
      />
    </SvgIcon>
  )
}
