export class NotFoundError extends Error {
  status = 404

  constructor(message = 'Page not found') {
    super(message)
    Object.setPrototypeOf(this, NotFoundError.prototype)
  }
}

export class Unauthorized extends Error {
  status = 403

  constructor(message = 'Unauthorized access') {
    super(message)
    Object.setPrototypeOf(this, Unauthorized.prototype)
  }
}

export type ErrorPageType = NotFoundError | Unauthorized
