import { Formik } from 'formik'
import { FC, ReactNode } from 'react'

export const trainingSearchFormDefaultValues = {
  name: '',
  type: '',
  sort: '', //BEST_MATCH,
  per_page: 5,
  page: 1
}

type TrainingSearchFormContextProps = {
  children: ReactNode
}

export const TrainingSearchFormContext: FC<TrainingSearchFormContextProps> = ({ children }) => {
  return (
    <Formik initialValues={trainingSearchFormDefaultValues} onSubmit={() => undefined}>
      {children}
    </Formik>
  )
}
