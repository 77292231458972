import { TenantDto } from '@/services/api/tenant/tenants'
import { ListItemText, Tooltip, Typography } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import React, { useEffect, useRef, useState } from 'react'
import { menuItemStyles, nameTypographyStyles, slopStyles } from './TenantSearchItemStyles'

interface TenantSearchMenuItemProps {
  tenant: TenantDto
  subdomain: string
  onSelect: (tenant: TenantDto) => void
}

export const TenantSearchMenuItem: React.FC<TenantSearchMenuItemProps> = ({ tenant, subdomain, onSelect }) => {
  const [isOverflowing, setIsOverflowing] = useState(false)
  const textRef = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        const { scrollWidth, clientWidth } = textRef.current
        setIsOverflowing(scrollWidth > clientWidth)
      }
    }
    checkOverflow()
  }, [tenant.name])

  const nameTypography = (
    <Typography ref={textRef} sx={nameTypographyStyles}>
      {tenant.name}
    </Typography>
  )

  return (
    <MenuItem
      disabled={tenant.subdomain === subdomain}
      key={tenant.id}
      value={tenant.subdomain}
      onClick={() => onSelect(tenant)}
      sx={menuItemStyles}>
      {isOverflowing ? (
        <Tooltip title={tenant.name} placement="right" arrow slotProps={slopStyles}>
          <ListItemText primary={nameTypography} />
        </Tooltip>
      ) : (
        <ListItemText primary={nameTypography} />
      )}
    </MenuItem>
  )
}
