import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'
import CustomPalette from '@/theme/CustomPalette'

const cardListStyles: {
  container: SxProps<Theme>
  overdueButton: SxProps<Theme>
  completedButton: SxProps<Theme>
  buttonTypography: SxProps<Theme>
  cardActions: SxProps<Theme>
  viewButton: SxProps<Theme>
  repeatTypography: SxProps<Theme>
} = {
  container: {
    m: 1
  },
  overdueButton: {
    fontSize: 12,
    backgroundColor: '#C74E29',
    color: 'white',
    borderRadius: 20,
    pl: '13px',
    pr: '13px',
    pt: '2px',
    pb: '2px'
  },
  completedButton: {
    fontSize: 12,
    backgroundColor: '#4D824F',
    color: 'white',
    borderRadius: 20,
    pl: '13px',
    pr: '13px',
    pt: '2px',
    pb: '2px'
  },
  buttonTypography: {
    fontSize: 12,
    fontFamily: 'BeausiteClassic-Regular',
    color: CustomPalette.NightBlue400,
    marginLeft: 'auto'
  },
  cardActions: {
    ml: 1,
    mr: 1
  },
  viewButton: {
    borderRadius: 20,
    fontSize: 14,
    fontFamily: 'BeausiteClassic-Regular',
    backgroundColor: CustomPalette.PilotBlue,
    color: '#fff',
    pl: '13px',
    pr: '13px',
    '&:hover': {
      bgcolor: '#D7D9F7',
      color: CustomPalette.PilotBlue
    }
  },
  repeatTypography: {
    ml: 2,
    marginLeft: 'auto',
    fontSize: 12
  }
}

export default cardListStyles

export const textStyles = {
  title: {
    mt: 1,
    mb: 1,
    fontSize: 20,
    fontWeight: '600',
    fontFamily: 'BeausiteClassic-Regular',
    color: CustomPalette.NightBlue400
  },
  duration: {
    mb: 1.5,
    fontSize: 14,
    fontFamily: 'BeausiteClassic-Regular',
    color: CustomPalette.NightBlue400
  },
  description: {
    mt: 4,
    mb: 0.5,
    fontSize: 14,
    fontFamily: 'BeausiteClassic-Regular',
    color: CustomPalette.NightBlue400
  }
}
