import { css, keyframes } from '@emotion/react'

import CustomPalette from '@/theme/CustomPalette'

const shake = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }`

export const errorMixin = css`
  animation: ${shake} 600ms ease;
`

export const WrapperBoxStyle = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh'
}

export const ContainerBoxStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flexGrow: 1,
  alignItems: 'center'
}

export const TypographyH4Style = {
  fontFamily: 'BeausiteFit-Medium',
  color: CustomPalette.PilotBlue,
  mt: 12,
  mb: 4,
  width: '213px',
  wordBreak: 'break-word'
}

export const TypographySpanStyle = { color: CustomPalette.NightBlue200 }

export const TypographyEmailStyle = {
  color: CustomPalette.SecondaryNightBlue900,
  fontWeight: 390,
  marginBottom: '33px'
}

export const FormControlStyle = (errors: any) => ({
  marginBottom: '1rem',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: CustomPalette.SecondaryNightBlue600,
      borderRadius: '10px'
    },
    '&.Mui-focused fieldset': {
      borderColor: CustomPalette.PilotBlue
    }
  },
  '& .MuiOutlinedInput-input': {
    backgroundColor: errors ? 'pink' : CustomPalette.white
  }
})

export const InputLabelStyle = {
  '&.Mui-focused': { color: CustomPalette.PilotBlue },
  color: CustomPalette.SecondaryNightBlue900,
  fontSize: 16,
  margin: '0 0 -10px -14px',
  position: 'static'
}

export const InputStyle = {
  backgroundColor: CustomPalette.white,
  '& .MuiInputBase-input, & .MuiOutlinedInput-input': {
    '::placeholder': {
      color: CustomPalette.SecondaryNightBlue600,
      fontSize: '16px',
      fontFamily: '"Public Sans", sans-serif',
      fontWeight: '400'
    },
    '::-webkit-input-placeholder': {
      color: CustomPalette.SecondaryNightBlue600,
      fontSize: '16px',
      fontFamily: '"Public Sans", sans-serif',
      fontWeight: '400'
    },
    ':-ms-input-placeholder': {
      color: CustomPalette.SecondaryNightBlue600,
      fontSize: '16px',
      fontFamily: '"Public Sans", sans-serif',
      fontWeight: '400'
    }
  }
}

export const MailIconStyle = {
  color: CustomPalette.SecondaryNightBlue600,
  backgroundColor: CustomPalette.white,
  fontSize: '23px',
  mb: -2,
  paddingBottom: '18px'
}

export const ArrowForwardIconStyle = {
  transition: '1s ease',
  '&:hover': {
    transition: ' 0.5s ease'
  }
}

export const ButtonStyle = {
  width: { xs: '100%', sm: '30%', md: '153px' },
  p: 1.5,
  borderRadius: '24px',
  backgroundColor: CustomPalette.PilotBlue,
  color: CustomPalette.white,
  border: '1px solid transparent',
  transition: '0.5s ease',
  '&:hover': {
    backgroundColor: CustomPalette.SecondaryCloud,
    color: CustomPalette.PilotBlue,
    borderColor: CustomPalette.PilotBlue,
    transition: ' 0.5s ease'
  },
  textTransform: 'none',
  fontSize: 16,
  fontWeight: '200',
  mt: 2
}

export const FooterBoxStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  pb: 4
}
