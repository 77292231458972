import { Route } from '@/core/router.types'
import type Details from './Details/Details'
import type Tenants from './Tenants'
/**
 * Tenants page routes.
 */

export default [
  {
    menu: { label: 'customerOverview', icon: 'HeadSetIcon' },
    path: '/tenants',
    query: 'tenants',
    header: 'backOffice',
    headerStyles: {
      color: '#151830',
      marginLeft: '26px',
      marginBottom: '12px'
    },
    authorize: ['customer_success'],
    sidebarTab: true,
    component: () => import(/* webpackChunkName: "Tenants" */ /* webpackMode: "eager" */ './Tenants'),
    response: (data) => ({
      title: 'Tenants',
      description: 'Use the search to start support tasks for tenants or create a new tenant.',
      props: data
    })
  } as unknown as Route<typeof Tenants>,
  {
    // menu: { label: 'Details', icon: 'BookmarksIcon' },
    path: '/tenants/:tenantId',
    query: '',
    authorize: ['customer_success'],
    component: () => import(/* webpackChunkName: "Details" */ './Details/Details'),
    response: (data) => ({
      title: 'Tenants',
      description: 'Use the search to start support tasks for tenants or create a new tenant.',
      props: data
    })
  } as unknown as Route<typeof Details>
] as const
