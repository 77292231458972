import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const MenuFold = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon style={{ fontSize: '16px' }} role="img" viewBox="0 0 18 16" width="10" height="50" {...props}>
      <path
        d="M6.67892 6.4382H17.3932C17.4914 6.4382 17.5718 6.35784 17.5718 6.25963V5.00963C17.5718 4.91141 17.4914 4.83106 17.3932 4.83106H6.67892C6.58071 4.83106 6.50035 4.91141 6.50035 5.00963V6.25963C6.50035 6.35784 6.58071 6.4382 6.67892 6.4382ZM6.50035 10.9918C6.50035 11.09 6.58071 11.1703 6.67892 11.1703H17.3932C17.4914 11.1703 17.5718 11.09 17.5718 10.9918V9.74177C17.5718 9.64356 17.4914 9.5632 17.3932 9.5632H6.67892C6.58071 9.5632 6.50035 9.64356 6.50035 9.74177V10.9918ZM17.7504 0.143555H0.250349C0.152134 0.143555 0.0717773 0.223912 0.0717773 0.322126V1.57213C0.0717773 1.67034 0.152134 1.7507 0.250349 1.7507H17.7504C17.8486 1.7507 17.9289 1.67034 17.9289 1.57213V0.322126C17.9289 0.223912 17.8486 0.143555 17.7504 0.143555ZM17.7504 14.2507H0.250349C0.152134 14.2507 0.0717773 14.3311 0.0717773 14.4293V15.6793C0.0717773 15.7775 0.152134 15.8578 0.250349 15.8578H17.7504C17.8486 15.8578 17.9289 15.7775 17.9289 15.6793V14.4293C17.9289 14.3311 17.8486 14.2507 17.7504 14.2507ZM0.14767 8.15472L3.63651 10.9025C3.76597 11.0052 3.95794 10.9136 3.95794 10.7485V5.25293C3.95794 5.08775 3.76821 4.99623 3.63651 5.09891L0.14767 7.84668C0.124211 7.86492 0.105228 7.88828 0.0921698 7.91497C0.0791117 7.94166 0.0723237 7.97098 0.0723237 8.0007C0.0723237 8.03041 0.0791117 8.05974 0.0921698 8.08643C0.105228 8.11312 0.124211 8.13648 0.14767 8.15472Z"
        fill="#6F708E"
      />
    </SvgIcon>
  )
}
