export interface IAuthState {
  accessToken?: string
  refreshToken?: string
}
export interface ITenantState {
  id: string
  default_language_tag: string
  details: string
  name: string
  odoo_partner_id: string
  subdomain: string
  logo_url?: string
  theme_color?: string
}

export enum UserRoles {
  CustomerSuccess = 'customer_success',
  CustomerManager = 'customer_manager',
  Participant = 'participant'
}

export type UserData = {
  username: string
  roles: UserRoles[]
  tenant_id: string
  token: UserProfileData
}

export type UserProfileData = {
  family_name: string
  given_name: string
  email: string
}

export type AuthState = {
  session?: IAuthState
  tenant: ITenantState
  user?: UserData
  showLoggedOutModal?: boolean
}
