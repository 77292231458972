import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const BookmarkBookClose = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon style={{ fontSize: '26px' }} role="img" viewBox="0 0 24 24" {...props}>
      <path
        d="M4 19V5C4 3.89543 4.89543 3 6 3H19.4C19.7314 3 20 3.26863 20 3.6V16.7143"
        stroke="#151830"
        stroke-width="1.5"
        stroke-linecap="round"
        fill="none"
      />
      <path
        d="M8 3V11L10.5 9.4L13 11V3"
        stroke="#151830"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <path d="M6 17H20" stroke="#151830" stroke-width="1.5" stroke-linecap="round" fill="none" />
      <path d="M6 21H20" stroke="#151830" stroke-width="1.5" stroke-linecap="round" fill="none" />
      <path
        d="M6 21C4.89543 21 4 20.1046 4 19C4 17.8954 4.89543 17 6 17"
        stroke="#151830"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
    </SvgIcon>
  )
}
