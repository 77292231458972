import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const ParticipantsClose = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon style={{ fontSize: '28px' }} role="img" viewBox="0 0 24 24" {...props}>
      <path
        d="M1 20V19C1 15.134 4.13401 12 8 12C11.866 12 15 15.134 15 19V20"
        stroke="#151830"
        stroke-width="1.5"
        stroke-linecap="round"
        fill="none"
      />
      <path
        d="M13 14C13 11.2386 15.2386 9 18 9C20.7614 9 23 11.2386 23 14V14.5"
        stroke="#151830"
        stroke-width="1.5"
        stroke-linecap="round"
        fill="none"
      />
      <path
        d="M8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12Z"
        stroke="#151830"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <path
        d="M18 9C19.6569 9 21 7.65685 21 6C21 4.34315 19.6569 3 18 3C16.3431 3 15 4.34315 15 6C15 7.65685 16.3431 9 18 9Z"
        stroke="#151830"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
    </SvgIcon>
  )
}
