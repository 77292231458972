import React, { FC, useCallback, useMemo } from 'react'
import { useFormikContext } from 'formik'
import { Box, InputAdornment, TextField } from '@mui/material'

import { CourseSearchFormValues } from '@/features/course'
import { courseSearchFormDefaultValues } from './CourseSearchFormContext'
import { CourseSearchStyle } from './CourseSearchStyle'
import { SearchIcon } from '@/icons'

type SearchBarProps = {
  placeholder: string
}

export const CourseSearch: FC<SearchBarProps> = ({ placeholder }) => {
  const { values, handleChange, touched, setFieldValue } = useFormikContext<CourseSearchFormValues>()

  const handleChangeAndResetPage = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleChange(e)
      setFieldValue('page', courseSearchFormDefaultValues.page)
    },
    [handleChange, setFieldValue]
  )

  return useMemo(
    () => (
      <Box sx={{ mt: 3, ml: 3, mb: 3, maxWidth: 500 }}>
        <TextField
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            )
          }}
          placeholder={placeholder}
          variant="outlined"
          name="name"
          sx={CourseSearchStyle}
          value={values.name}
          onChange={handleChangeAndResetPage}
          error={touched.name}
          helperText={touched.name}
        />
      </Box>
    ),
    [values, handleChange, touched, handleChangeAndResetPage]
  )
}
