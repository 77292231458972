import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const Home = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon style={{ fontSize: '28px' }} role="img" viewBox="0 0 18 18" {...props}>
      <path
        d="M1.5 6L8.73167 2.38416C8.90059 2.29971 9.09941 2.29971 9.26833 2.38416L16.5 6"
        stroke="#6F708E"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <path
        d="M15 8.25V13.75C15 14.8546 14.1046 15.75 13 15.75H5C3.89543 15.75 3 14.8546 3 13.75V8.25"
        stroke="#6F708E"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
    </SvgIcon>
  )
}
