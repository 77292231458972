import { Formik } from 'formik'
import React, { FC, ReactNode } from 'react'

export const tenantSearchFormDefaultValues = {
  name: '',
  type: '',
  sort: '', //BEST_MATCH,
  per_page: 5,
  page: 1
}

type TenantSearchFormContextProps = {
  children: ReactNode
}

export const TenantSearchFormContext: FC<TenantSearchFormContextProps> = ({ children }) => {
  return (
    <Formik initialValues={tenantSearchFormDefaultValues} onSubmit={() => undefined}>
      {children}
    </Formik>
  )
}
