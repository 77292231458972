import { CSSProperties, ReactNode, Ref, forwardRef } from 'react'

// material-ui
import {
  Card,
  CardContent,
  CardContentProps,
  CardHeader,
  CardHeaderProps,
  CardProps,
  Divider,
  Typography
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

// types
import { ThemeMode } from '../../theme/types/config'
import { KeyedObject } from '../../theme/types/root'
import React from 'react'

// header style
const headerSX = {
  p: 2.5,
  '& .MuiCardHeader-action': { m: '0px auto', alignSelf: 'center' }
}

// ==============================|| CUSTOM - MAIN CARD ||============================== //

export interface MainCardProps extends KeyedObject {
  border?: boolean
  boxShadow?: boolean
  children: ReactNode | string
  subheader?: ReactNode | string
  style?: CSSProperties
  content?: boolean
  contentSX?: CardContentProps['sx']
  darkTitle?: boolean
  divider?: boolean
  sx?: CardProps['sx']
  secondary?: CardHeaderProps['action']
  shadow?: string
  elevation?: number
  title?: ReactNode | string
  modal?: boolean
}

export const MainCard = forwardRef(
  (
    {
      border = true,
      boxShadow,
      children,
      subheader,
      content = true,
      contentSX = {},
      darkTitle,
      divider = true,
      elevation,
      secondary,
      shadow,
      sx = {},
      title,
      modal = false,
      ...others
    }: MainCardProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const theme = useTheme()
    boxShadow = theme.palette.mode === ThemeMode.DARK ? boxShadow || true : boxShadow

    return (
      <Card
        elevation={elevation || 0}
        ref={ref}
        {...others}
        sx={{
          position: 'relative',
          border: border ? '2px solid' : 'none',
          borderRadius: 5,
          borderColor: theme.palette.mode === ThemeMode.DARK ? theme.palette.divider : '#e6ebf1',
          boxShadow:
            boxShadow && (!border || theme.palette.mode === ThemeMode.DARK)
              ? shadow || theme.customShadows.z1
              : 'inherit',
          ':hover': {
            boxShadow: boxShadow ? shadow || theme.customShadows.z1 : 'inherit'
          },
          ...(modal && {
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: `calc( 100% - 50px)`, sm: 'auto' },
            '& .MuiCardContent-root': {
              overflowY: 'auto',
              minHeight: 'auto',
              maxHeight: `calc(100vh - 200px)`
            }
          }),
          ...sx
        }}>
        {!darkTitle && title && (
          <CardHeader
            sx={headerSX}
            titleTypographyProps={{ variant: 'subtitle1' }}
            title={title}
            action={secondary}
            subheader={subheader}
          />
        )}
        {darkTitle && title && (
          <CardHeader sx={headerSX} title={<Typography variant="h4">{title}</Typography>} action={secondary} />
        )}

        {title && divider && <Divider />}

        {content && <CardContent sx={contentSX}>{children}</CardContent>}
        {!content && children}
      </Card>
    )
  }
)
