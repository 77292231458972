import { SvgIcon, SvgIconProps } from '@mui/material'

export const DataProtection = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon style={{ fontSize: '28px' }} role="img" viewBox="0 0 25 30" {...props}>
      <mask
        id="mask0_1130_7499"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="30">
        <path d="M24.3335 0H0.333496V30H24.3335V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_1130_7499)">
        <path
          d="M23.253 29.0201H4.57331C2.77386 29.0201 1.31982 27.5754 1.31982 25.7876C1.31982 23.9998 2.77943 22.5551 4.57331 22.5551L23.2975 22.3669L23.3421 2.68982C23.3421 1.74887 22.5733 0.979492 21.6207 0.979492H3.04127C2.08863 0.979492 1.31982 1.74333 1.31982 2.68982V25.9426"
          stroke="#151830"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
          fill="none"
        />
      </g>
      <path
        d="M16.8293 11.4077H8.29443V17.1033H16.8293V11.4077Z"
        stroke="#463ED7"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <mask
        id="mask1_1130_7499"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="30">
        <path d="M24.3335 0H0.333496V30H24.3335V0Z" fill="white" />
      </mask>
      <g mask="url(#mask1_1130_7499)" fill="none">
        <path
          d="M15.431 11.3302V9.08298C15.431 7.51102 14.1497 6.23242 12.562 6.23242C10.9742 6.23242 9.69287 7.50548 9.69287 9.08298V11.3302"
          stroke="#463ED7"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          fill="none"
        />
      </g>
    </SvgIcon>
  )
}
