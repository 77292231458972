import { tenantApi as api } from './api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTenantLanguagesTenantsTenantIdAvailableLanguagesGet: build.query<
      GetTenantLanguagesTenantsTenantIdAvailableLanguagesGetApiResponse,
      GetTenantLanguagesTenantsTenantIdAvailableLanguagesGetApiArg
    >({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/available_languages` })
    }),
    languagesGetLanguagesLanguagesGet: build.query<
      LanguagesGetLanguagesLanguagesGetApiResponse,
      LanguagesGetLanguagesLanguagesGetApiArg
    >({
      query: (queryArg) => ({ url: `/languages/`, params: { display_in_ui: queryArg.displayInUi } })
    }),
    languagesCreateLanguageLanguagesPost: build.mutation<
      LanguagesCreateLanguageLanguagesPostApiResponse,
      LanguagesCreateLanguageLanguagesPostApiArg
    >({
      query: (queryArg) => ({ url: `/languages/`, method: 'POST', body: queryArg.createLanguageDto })
    }),
    languagesGetLanguageByTagLanguagesTagGet: build.query<
      LanguagesGetLanguageByTagLanguagesTagGetApiResponse,
      LanguagesGetLanguageByTagLanguagesTagGetApiArg
    >({
      query: (queryArg) => ({ url: `/languages/${queryArg.tag}` })
    }),
    languagesDeleteLanguageByTagLanguagesTagDelete: build.mutation<
      LanguagesDeleteLanguageByTagLanguagesTagDeleteApiResponse,
      LanguagesDeleteLanguageByTagLanguagesTagDeleteApiArg
    >({
      query: (queryArg) => ({ url: `/languages/${queryArg.tag}`, method: 'DELETE' })
    })
  }),
  overrideExisting: true
})
export { injectedRtkApi as enhancedApi }
export type GetTenantLanguagesTenantsTenantIdAvailableLanguagesGetApiResponse =
  /** status 200 Successful Response */ LanguageDto[]
export type GetTenantLanguagesTenantsTenantIdAvailableLanguagesGetApiArg = {
  tenantId: string
}
export type LanguagesGetLanguagesLanguagesGetApiResponse = /** status 200 Successful Response */ LanguageDto[]
export type LanguagesGetLanguagesLanguagesGetApiArg = {
  /** If not provided, all languages will be returned.  */
  displayInUi?: boolean
}
export type LanguagesCreateLanguageLanguagesPostApiResponse = /** status 201 Successful Response */ LanguageDto
export type LanguagesCreateLanguageLanguagesPostApiArg = {
  createLanguageDto: CreateLanguageDto
}
export type LanguagesGetLanguageByTagLanguagesTagGetApiResponse = /** status 200 Successful Response */ LanguageDto
export type LanguagesGetLanguageByTagLanguagesTagGetApiArg = {
  tag: string
}
export type LanguagesDeleteLanguageByTagLanguagesTagDeleteApiResponse = /** status 204 Successful Response */ void
export type LanguagesDeleteLanguageByTagLanguagesTagDeleteApiArg = {
  tag: string
}
export type LanguageDto = {
  tag: string
  display_name: string
  display_in_ui: boolean
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type CreateLanguageDto = {
  tag: string
  display_name: string
}
export const {
  useGetTenantLanguagesTenantsTenantIdAvailableLanguagesGetQuery,
  useLanguagesGetLanguagesLanguagesGetQuery,
  useLanguagesCreateLanguageLanguagesPostMutation,
  useLanguagesGetLanguageByTagLanguagesTagGetQuery,
  useLanguagesDeleteLanguageByTagLanguagesTagDeleteMutation
} = injectedRtkApi
