import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const FavoriteTraining = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon style={{ fontSize: '20px' }} role="img" viewBox="0 0 18 18" {...props}>
      <path
        d="M3 14.25V3.75C3 2.92157 3.67157 2.25 4.5 2.25H14.55C14.7985 2.25 15 2.45147 15 2.7V12.5357"
        stroke="#6F708E"
        stroke-width="1.2"
        stroke-linecap="round"
        fill="none"
      />
      <path
        d="M12 6.58481C12 7.03588 11.8219 7.46912 11.5037 7.78957C10.7714 8.52757 10.0612 9.29708 9.30157 10.0082C9.1275 10.1689 8.85128 10.163 8.68463 9.9951L6.49613 7.78957C5.83463 7.12294 5.83463 6.04669 6.49613 5.38004C7.16414 4.70684 8.2524 4.70684 8.92035 5.38004L8.99992 5.4602L9.07942 5.38009C9.39975 5.05714 9.83595 4.875 10.2916 4.875C10.7473 4.875 11.1835 5.05713 11.5037 5.38004C11.8219 5.70055 12 6.13376 12 6.58481Z"
        stroke="#6F708E"
        stroke-width="1.2"
        stroke-linejoin="round"
        fill="none"
      />
      <path d="M4.5 12.75H15" stroke="#6F708E" stroke-width="1.2" stroke-linecap="round" fill="none" />
      <path d="M4.5 15.75H15" stroke="#6F708E" stroke-width="1.2" stroke-linecap="round" fill="none" />
      <path
        d="M4.5 15.75C3.67157 15.75 3 15.0784 3 14.25C3 13.4216 3.67157 12.75 4.5 12.75"
        stroke="#6F708E"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
    </SvgIcon>
  )
}
