import { Box, Link, Typography } from '@mui/material'
import { To } from 'history'
import * as React from 'react'
import { useHistory } from '../../../hooks'
import { boxStyles, linkStyles } from './DataListItemStyles'

type DataListItemProps<T> = {
  data: T
  nameItem: string
  objectType: string
}

export const DataListItem = <T extends Record<string, unknown>>(props: DataListItemProps<T>): JSX.Element => {
  const history = useHistory()
  const rootUrl = new URL(document?.URL)
  const [subdomain, setSubdomain] = React.useState<string>('')

  const handleLink = (e: React.MouseEvent<HTMLAnchorElement>, tenant: DataListItemProps<T>) => {
    const newUrl = rootUrl.href.replace(`${subdomain}.`, `${tenant.data.subdomain}.`) + `/${tenant.data.id}`
    history.push(newUrl as To, {})
  }

  React.useEffect(() => {
    const subdomain = rootUrl.hostname.replace('www.', '')
    setSubdomain(subdomain.split('.')[0])
  }, [rootUrl])

  const isLinkDisabled = props.data.subdomain === subdomain

  return (
    <Box sx={boxStyles}>
      <Typography color="textPrimary" variant="body1">
        <Link key={props.objectType} sx={linkStyles} color="inherit" onClick={(event) => handleLink(event, props)}>
          {props.nameItem}
        </Link>
      </Typography>
    </Box>
  )
}
