import { SvgIcon, SvgIconProps } from '@mui/material'

export const SearchTenantIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="#F8F8FA" />
        <path
          d="M18.625 18.625L21.25 21.25"
          stroke="#8484A2"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.75 15.25C10.75 17.7353 12.7647 19.75 15.25 19.75C16.4948 19.75 17.6216 19.2446 18.4362 18.4278C19.2481 17.6138 19.75 16.4905 19.75 15.25C19.75 12.7647 17.7353 10.75 15.25 10.75C12.7647 10.75 10.75 12.7647 10.75 15.25Z"
          stroke="#8484A2"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#9999B7" />
      </svg>
    </SvgIcon>
  )
}
