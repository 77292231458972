import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const Compliance = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon style={{ fontSize: '28px' }} role="img" viewBox="0 0 24 30" {...props}>
      <path
        d="M22.2931 15.2164V2.68943C22.2931 1.74731 21.5448 0.980469 20.6255 0.980469H2.62413C1.70482 0.980469 0.956543 1.74731 0.956543 2.68943V27.3106C0.956543 28.2527 1.70482 29.0195 2.62413 29.0195H12.9984"
        stroke="#151830"
        stroke-width="2.6"
        stroke-linecap="round"
        fill="none"
      />
      <path
        d="M13.3779 23.8815L14.4576 25.1632C15.0562 25.8698 16.0824 25.9191 16.7505 25.2727L22.1541 20.0527"
        stroke="#463ED7"
        stroke-width="2.6"
        stroke-linecap="round"
        fill="none"
      />
      <path d="M6.08203 6.57275H11.7743" stroke="#151830" stroke-width="2.6" stroke-linecap="round" fill="none" />
      <path d="M6.08203 12.4502H17.5093" stroke="#151830" stroke-width="2.6" stroke-linecap="round" fill="none" />
      <path d="M6.08203 18.333H9.86083" stroke="#151830" stroke-width="2.6" stroke-linecap="round" fill="none" />
    </SvgIcon>
  )
}
