import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const MenuOpen = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon style={{ fontSize: '16px' }} role="img" viewBox="0 0 18 16" {...props}>
      <path
        d="M11.0892 6.59438H1.44636C1.35796 6.59438 1.28564 6.52206 1.28564 6.43366V5.30866C1.28564 5.22027 1.35796 5.14795 1.44636 5.14795H11.0892C11.1776 5.14795 11.2499 5.22027 11.2499 5.30866V6.43366C11.2499 6.52206 11.1776 6.59438 11.0892 6.59438ZM11.2499 10.6926C11.2499 10.781 11.1776 10.8533 11.0892 10.8533H1.44636C1.35796 10.8533 1.28564 10.781 1.28564 10.6926V9.56759C1.28564 9.4792 1.35796 9.40688 1.44636 9.40688H11.0892C11.1776 9.40688 11.2499 9.4792 11.2499 9.56759V10.6926ZM1.12493 0.929199H16.8749C16.9633 0.929199 17.0356 1.00152 17.0356 1.08991V2.21491C17.0356 2.30331 16.9633 2.37563 16.8749 2.37563H1.12493C1.03654 2.37563 0.964214 2.30331 0.964214 2.21491V1.08991C0.964214 1.00152 1.03654 0.929199 1.12493 0.929199ZM1.12493 13.6256H16.8749C16.9633 13.6256 17.0356 13.698 17.0356 13.7863V14.9113C17.0356 14.9997 16.9633 15.0721 16.8749 15.0721H1.12493C1.03654 15.0721 0.964214 14.9997 0.964214 14.9113V13.7863C0.964214 13.698 1.03654 13.6256 1.12493 13.6256ZM16.9673 8.13925L13.8274 10.6122C13.7109 10.7046 13.5381 10.6223 13.5381 10.4736V5.52764C13.5381 5.37898 13.7089 5.29661 13.8274 5.38902L16.9673 7.86201C16.9885 7.87843 17.0055 7.89945 17.0173 7.92347C17.029 7.94749 17.0352 7.97388 17.0352 8.00063C17.0352 8.02737 17.029 8.05376 17.0173 8.07779C17.0055 8.10181 16.9885 8.12283 16.9673 8.13925Z"
        fill="#6F708E"
      />
    </SvgIcon>
  )
}
