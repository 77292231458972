import { ITenantState } from '@/features/auth/types'
import { useHistory } from '@/hooks'
import { TenantDto } from '@/services/api/tenant/tenants'
import { Divider } from '@mui/material'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { To } from 'history'
import React, { useEffect, useMemo, useRef } from 'react'
import { TenantSearchField } from './TenantSearchField'
import { TenantSearchIcon } from './TenantSearchIcon'
import {
  boxStyles,
  divStickyStyles,
  dividerStyles,
  formControlStyles,
  menuProps,
  selectStyles
} from './TenantSearchItemStyles'
import { TenantSearchMenuItem } from './TenantSearchMenuItem'

interface TenantSearchItemProps {
  subdomain: string
  isNavBarOpen: boolean
  tenantsList: TenantDto[]
  handleForceOpen: () => void
}

export const TenantSearchItem: React.FC<TenantSearchItemProps> = ({
  isNavBarOpen,
  subdomain,
  tenantsList,
  handleForceOpen
}) => {
  const history = useHistory()
  const rootUrl = new URL(document?.URL)

  const [open, setOpen] = React.useState(false)
  const [searchTerm, setSearchTerm] = React.useState<string>('')
  const textFieldRef = useRef<HTMLInputElement>(null)
  const [currentTenant, setCurrentTenant] = React.useState<ITenantState>()
  const [selectedTenants, setSelectedTenants] = React.useState<string[]>([])

  const handleTenantSelected = (tenant: TenantDto) => {
    if (tenant) {
      rootUrl.pathname = '/tenants'
      const domain = rootUrl.hostname.replace('www.', '')
      const subdomain = domain.split('.')[0]
      const newUrl = rootUrl.href.replace(`${subdomain}.`, `${tenant.subdomain}.`) + `/${tenant.id}`
      history.push(newUrl as To, {})
    }
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (!isNavBarOpen) {
      handleForceOpen()

      setTimeout(() => {
        if (!open) handleOpen()
      }, 300)
    } else {
      if (currentTenant?.subdomain === subdomain) return
      if (!open) {
        handleOpen()
      } else {
        handleClose()
      }
    }
  }

  const filteredNames = useMemo(() => {
    const result = tenantsList
      ?.filter(({ name }) => name.toLowerCase().includes(searchTerm.toLowerCase()))
      .sort((a, b) => a.name.localeCompare(b.name))

    return result
  }, [searchTerm, tenantsList])

  useEffect(() => {
    if (tenantsList && tenantsList.length) {
      setCurrentTenant(tenantsList.find((item) => item.subdomain === subdomain) as unknown as ITenantState)
      setSelectedTenants(tenantsList.map((tenant) => tenant.id))
    }
  }, [tenantsList])

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        if (textFieldRef.current) {
          textFieldRef.current.focus()
        }
      }, 100)
    }
  }, [open])

  return useMemo(
    () => (
      <>
        <Box sx={boxStyles} onClick={handleMenu}>
          <TenantSearchIcon icon="SearchTenantIcon" isTenantSearch={true} isSidebarOpen={isNavBarOpen} />
          <FormControl sx={formControlStyles}>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={selectedTenants}
              onClose={handleClose}
              onOpen={handleOpen}
              renderValue={() => currentTenant && currentTenant.name}
              open={open}
              displayEmpty
              sx={selectStyles}
              IconComponent={isNavBarOpen ? undefined : () => null}
              MenuProps={menuProps}>
              <div style={divStickyStyles}>
                <TenantSearchField
                  searchTerm={searchTerm}
                  handleSearchChange={handleSearchChange}
                  textFieldRef={textFieldRef}
                />
                <Divider style={dividerStyles} />
              </div>

              {filteredNames &&
                filteredNames.map((tenant: TenantDto) => (
                  <TenantSearchMenuItem
                    key={tenant.id}
                    tenant={tenant}
                    subdomain={subdomain}
                    onSelect={handleTenantSelected}
                  />
                ))}
            </Select>
          </FormControl>
        </Box>
      </>
    ),
    [filteredNames, tenantsList, open, selectedTenants, isNavBarOpen, searchTerm]
  )
}
