import { ErrorPageType } from '@/core/errors'
import { History } from '@/core/history'
import { Container, CssBaseline, Typography } from '@mui/material'
import * as React from 'react'

export interface ErrorPageProps {
  history: History
  error: ErrorPageType
}

export function ErrorPage(props: ErrorPageProps): JSX.Element {
  const { error } = props

  return (
    <Container sx={{ marginTop: '43vh' }}>
      <CssBaseline />
      <Container maxWidth="sm">
        <Typography
          variant="h1"
          align="center"
          sx={{
            fontSize: '2em',
            fontWeight: 300,
            '& strong': {
              fontWeight: 400
            }
          }}>
          <strong>Error {error.status || 500}</strong>: {error.message}
        </Typography>
      </Container>
    </Container>
  )
}
