import { Divider, Typography } from '@mui/material'
import { styled } from '@mui/system'

const Header = styled(Typography)(({ theme, headerStyles }) => ({
  fontSize: '13px',
  fontFamily: 'BeausiteClassic-Regular',
  color: '#363752',
  ...(headerStyles ?? {})
}))

type SidebarSectionProps = {
  header?: string
  showDivider?: boolean
  dividerStyles?: any
  headerStyles?: any
  children?: React.ReactNode
}

const SidebarSection: React.FC<SidebarSectionProps> = ({
  header,
  showDivider = false,
  dividerStyles = {},
  headerStyles = {},
  children
}) => {
  return (
    <>
      {showDivider && <Divider sx={dividerStyles} />}
      {header && (
        <Header variant="h6" sx={headerStyles}>
          {header}
        </Header>
      )}
      {children}
    </>
  )
}

export default SidebarSection
