import { ToolBar } from '@/common/components/ToolBar/ToolBar'
import { usePageVisited } from '@/hooks'
import { RootState, useTypedSelector } from '@/store'
import { Grid } from '@mui/material'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearch } from '../../hooks/useSearchCertificates'
import { CardList } from '../components/List/CardList'

const Certificates = (): JSX.Element => {
  const { t, i18n } = useTranslation('general')
  const accessedTenant = useTypedSelector((state: RootState) => state.authSlice.tenant)
  const { data: certificates = [], isFetching, isUninitialized } = useSearch(accessedTenant.id)
  const currentUser = useTypedSelector((state: RootState) => state.authSlice.user)
  const userFullName = currentUser ? currentUser.token.given_name + ' ' + currentUser.token.family_name : ''
  const isLoading = isFetching || isUninitialized
  usePageVisited('My Certificates')

  return useMemo(
    () => (
      <Grid>
        <Grid sx={{ ml: 2, mb: 2 }}>
          <ToolBar title={t('cert_certificate')} />
        </Grid>
        <Grid md={12} sx={{ mr: 4 }}>
          <Grid alignItems="center" justifyContent="space-between">
            <CardList userFullName={userFullName} list={certificates}></CardList>
          </Grid>
        </Grid>
      </Grid>
    ),
    [isLoading, certificates, i18n.language]
  )
}

export default Certificates
