import { SvgIcon, SvgIconProps } from '@mui/material'

export const Certificate = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon style={{ fontSize: '43px' }} role="img" viewBox="0 0 37 35" fill="none" {...props}>
      <path
        d="M18.5 21.3C24.0228 21.3 28.5 16.8228 28.5 11.3C28.5 5.77714 24.0228 1.29999 18.5 1.29999C12.9772 1.29999 8.5 5.77714 8.5 11.3C8.5 16.8228 12.9772 21.3 18.5 21.3Z"
        stroke="#151830"
        stroke-width="2.6"
        stroke-miterlimit="10"
        stroke-linecap="round"
        fill="none"
      />
      <path
        d="M18.4996 17.2C21.7581 17.2 24.3996 14.5585 24.3996 11.3C24.3996 8.04154 21.7581 5.40002 18.4996 5.40002C15.2411 5.40002 12.5996 8.04154 12.5996 11.3C12.5996 14.5585 15.2411 17.2 18.4996 17.2Z"
        stroke="#463ED7"
        stroke-width="2.6"
        stroke-miterlimit="10"
        stroke-linecap="round"
        fill="none"
      />
      <path
        d="M20.7997 11.3C20.7997 12.6 19.7997 13.6 18.4997 13.6C17.1997 13.6 16.1997 12.6 16.1997 11.3C16.1997 10 17.1997 9 18.4997 9"
        stroke="#463ED7"
        stroke-width="2.6"
        stroke-miterlimit="10"
        stroke-linecap="round"
        fill="none"
      />
      <path
        d="M9.8998 16.5L2.7998 28.2L8.7998 26.8L9.8998 31.3L16.1998 21"
        stroke="#151830"
        stroke-width="2.6"
        stroke-miterlimit="10"
        stroke-linecap="round"
        fill="none"
      />
      <path
        d="M26.8 16.7L33.9 28.2L27.8 26.7999L26.8 31.2999L20.5 21"
        stroke="#151830"
        stroke-width="2.6"
        stroke-miterlimit="10"
        stroke-linecap="round"
        fill="none"
      />
    </SvgIcon>
  )
}
