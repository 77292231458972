import React, { useEffect, useRef } from 'react'

const ImageToSvgConverter = ({ imageString }: { imageString: string }) => {
  const svgRef = useRef<SVGSVGElement>(null)

  useEffect(() => {
    if (imageString && svgRef.current) {
      const svg = svgRef.current
      // Clear existing content
      while (svg.firstChild) {
        svg.removeChild(svg.firstChild)
      }
      const image = document.createElementNS('http://www.w3.org/2000/svg', 'image')
      image.setAttribute('href', imageString)
      image.setAttribute('height', '100%')
      image.setAttribute('width', '100%')
      svg.appendChild(image)
    }
  }, [imageString])

  return imageString ? (
    <svg
      ref={svgRef}
      data-testid="image-to-svg-converter"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      height="100"
      width="100%"
      style={{ display: 'block', margin: 'auto' }}
    />
  ) : null
}

export default ImageToSvgConverter
