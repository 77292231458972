export const listItemButtonStyles = (isOpen: boolean, isTenant: boolean) => ({
  minHeight: 48,
  justifyContent: isOpen ? 'initial' : 'center',
  px: 2.5,
  '&.Mui-selected': {
    backgroundColor: '#D7D9F7',
    borderRight: isTenant ? 'none' : '5px solid #463ED7',
    color: '#363752'
  },
  '&:hover': {
    backgroundColor: '#D7D9F7',
    '& .MuiTypography-root': {
      color: '#363752'
    }
  }
})

export const listItemButtonTenantStyles = (isOpen: boolean) => ({
  minHeight: 48,
  justifyContent: isOpen ? 'initial' : 'center',
  px: 2.5,
  '&.Mui-selected': {
    backgroundColor: '#D7D9F7',
    borderRight: 'none',
    color: '#363752'
  },
  '&:hover': {
    backgroundColor: '#D7D9F7',
    '& .MuiTypography-root': {
      color: '#363752'
    }
  }
})

export const listItemTextStyles = (isOpen: boolean | undefined) => ({
  '& span': {
    fontSize: '16px',
    fontFamily: 'BeausiteClassic-Regular',
    color: '#151830',
    fontWeight: '300',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: '.2rem'
  },
  opacity: isOpen ? 1 : 0
})
