import AddIcon from '@mui/icons-material/Add'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { DataList, ToolBar } from '@/common/components'
import CustomButton from '@/common/components/CustomButton/CustomButton'
import { MainCard } from '@/common/components/MainCard'
import tenant, {
  MenusNavItem,
  TenantSearch,
  TenantSearchFormContext,
  tenantSlice,
  useSearchTenants
} from '@/features/tenant'
import { usePageVisited } from '@/hooks'
import { useTypedDispatch } from '@/store'
import FontFaceLoader from '@/theme/CustomFont'
import CustomPalette from '@/theme/CustomPalette'

type PageProps = {
  query?: string
}

const Tenants = ({ ...rest }: PageProps): JSX.Element => {
  const { t, i18n } = useTranslation('general')
  const dispatch = useTypedDispatch()
  usePageVisited('Tenants')

  const tenantMenus: MenusNavItem[] = [
    {
      index: '1',
      component: async () => {
        const module = await import(/* webpackChunkName: "TenantMenu" */ '@/features/tenant/menus/TenantMenu')
        return {
          default: module.TenantMenu
        }
      },
      props: { title: t('createCustomer') ?? 'Create Customer' }
    }
  ]

  const titles: string[] = [t('company')]

  const openMenu = () => {
    dispatch(tenantSlice.actions.openCreateTenantMenu(true))
  }

  const closeMenu = () => {
    dispatch(tenantSlice.actions.openCreateTenantMenu(false))
  }

  return (
    <>
      <FontFaceLoader />
      <ToolBar
        title={t('customerOverview') || ''}
        menus={tenantMenus}
        openAction={openMenu}
        closeAction={closeMenu}
        key={i18n.language}
      />
      <TenantSearchFormContext>
        <Box sx={{ mt: 3 }}>
          <MainCard sx={{ border: '1px solid', borderColor: CustomPalette.Air }}>
            <Box sx={{ m: 1, display: 'flex', justifyContent: 'space-between' }}>
              <TenantSearch placeholder={t('search')} />
              <CustomButton
                styles={{ borderRadius: '20px' }}
                startIcon={
                  <AddIcon
                    sx={{
                      transition: '1s ease',
                      '&:hover': {
                        transition: ' 0.5s ease'
                      }
                    }}
                  />
                }
                type="submit"
                onClick={openMenu}>
                {t('createCustomer')}
              </CustomButton>
            </Box>
            <DataList data={tenant} emptyList="No tenants Found" titles={titles} useSearchList={useSearchTenants} />
          </MainCard>
        </Box>
      </TenantSearchFormContext>
    </>
  )
}

export default Tenants
