import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-locize-backend'
import { locizePlugin } from 'locize'
import { initReactI18next } from 'react-i18next'

export const locizeOptions = {
  projectId: '1370ba4b-2280-4f3d-b049-a895bf27907a',
  apiKey: 'fe26f3b1-1f21-433a-881f-f1625fffe1d8',
  referenceLng: 'en'
}

i18n
  .use(locizePlugin)
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    backend: locizeOptions,
    locizeLastUsed: locizeOptions
  })

export default i18n
