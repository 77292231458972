import { AppBarProps } from '@mui/material'
import React, { useEffect } from 'react'

import { TenantData } from '../../../../core/types'
import { authSlice } from '../../../../features/auth'
import { useTypedDispatch } from '../../../../store'

type TenantMiddlewareProps = AppBarProps & {
  tenant: TenantData | null
  children: React.ReactNode
}

export const TenantMiddleware = ({ children, tenant }: TenantMiddlewareProps): JSX.Element => {
  const dispatch = useTypedDispatch()

  useEffect(() => {
    if (tenant) {
      dispatch(authSlice.actions.setCurrentTenant(tenant))
    }
  }, [tenant])

  return <>{children}</>
}
