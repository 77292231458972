import { Avatar, Link, ListItemIcon, ListItemText, Menu, MenuItem, MenuProps } from '@mui/material'

import i18n from '../locales/i18n'

type LanguageMenuProps = Omit<MenuProps, 'id' | 'role' | 'open' | 'anchorOrigin' | 'transformOrigin'>

export function LanguageMenu(props: LanguageMenuProps): JSX.Element {
  const { PaperProps, MenuListProps, ...other } = props

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng)
  }

  return (
    <Menu
      id="language-menu"
      role="menu"
      open={Boolean(props.anchorEl)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{ ...PaperProps, sx: { ...PaperProps?.sx } }}
      MenuListProps={{ ...MenuListProps, dense: true }}
      {...other}>
      <MenuItem component={Link} href="#" onClick={() => changeLanguage('en')}>
        <ListItemIcon sx={{ minWidth: 40 }} children={<Avatar sx={{ width: 20, height: 20 }} src="/flags/us.svg" />} />
        <ListItemText primary="en-GB" />
      </MenuItem>

      <MenuItem component={Link} href="#" onClick={() => changeLanguage('de')}>
        <ListItemIcon sx={{ minWidth: 40 }} children={<Avatar sx={{ width: 20, height: 20 }} src="/flags/de.svg" />} />
        <ListItemText primary="de-DE" />
      </MenuItem>
    </Menu>
  )
}
