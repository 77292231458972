import React, { FC, useEffect } from 'react'

import { useTypedDispatch } from '../../../../store'

type AuthenticatedRouteProps = {
  onlyPublic?: boolean
  children?: React.ReactNode
}

export const AuthenticatedRoute: FC<AuthenticatedRouteProps> = () => {
  const dispatch = useTypedDispatch()

  // Do we even need this anymore?

  return <></>
}
