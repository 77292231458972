import * as amplitude from '@amplitude/analytics-browser'
import { AppBar, AppBarProps, Button, Chip, IconButton, Toolbar } from '@mui/material'
import Stack from '@mui/material/Stack'
import { styled, useTheme } from '@mui/material/styles'
import Cookies from 'js-cookie'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { config as appConfig } from '../../../core'
import { useSignOut } from '../../../hooks'
import { AvatarIcon, Logout, MenuFold, MenuOpen } from '../../../icons'
import ExpandIcon from '../../../icons/ExpandIcon'
import { LanguageMenu, UserMenu } from '../../../menus'
import { RootState, useTypedDispatch, useTypedSelector } from '../../../store'
import FontFaceLoader from '../../../theme/CustomFont'
import CustomPalette from '../../../theme/CustomPalette'
import { LanguageSelector } from './Components/LanguageSelector/LanguageSelector'

const drawerWidth = 240

type AppToolBarProps = AppBarProps & {
  isOpen?: boolean
  onDrawerToggle: () => void
  onChangeTheme: () => void
}

const NavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: '#F8F8FA',
  boxShadow: 'none'
}))

export const AppToolBar = (props: AppToolBarProps): JSX.Element => {
  const { t } = useTranslation('general')
  const { isOpen, onChangeTheme, onDrawerToggle, ...other } = props
  const menuAnchorRef = React.createRef<HTMLButtonElement>()
  const theme = useTheme()
  const user = useTypedSelector((state: RootState) => state.authSlice.user)
  const dispatch = useTypedDispatch()
  const [selectedTenantLanguage, setSelectedTenantLanguage] = useState('')
  const accessedTenant = useTypedSelector((state: RootState) => state.authSlice.tenant)

  const [anchorEl, setAnchorEl] = React.useState({
    languageMenu: null as HTMLElement | null,
    userMenu: null as HTMLElement | null
  })

  function openUserMenu() {
    setAnchorEl((x) => ({ ...x, userMenu: menuAnchorRef.current }))
  }

  function closeUserMenu() {
    setAnchorEl((x) => ({ ...x, userMenu: null }))
  }

  function closeLanguageMenu() {
    setAnchorEl((x) => ({ ...x, languageMenu: null }))
  }

  const isAccessTokenValid = () => {
    const expiryTime = Cookies.get('access_token_expiry')
    if (!expiryTime) return false

    const currentTime = new Date().getTime()
    return currentTime < Number(expiryTime)
  }

  function signOut(event: React.MouseEvent): void {
    console.log('==== ATTEMPT TO SIGN OUT WHILE TOKEN IS VALID ====')
    if (!isAccessTokenValid()) {
      console.log('==== SIGN OUT WHILE TOKEN HAS EXPIRED ====')
      event.preventDefault()
      amplitude.reset()
      dispatch(useSignOut())
      window.location.href = `${appConfig.api.origin}/logout?redirect_url=${encodeURIComponent(
        appConfig.api.redirect_url
      )}`
    }
  }

  return (
    <NavbarRoot
      position="fixed"
      sx={{
        zIndex: () => theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }),
        marginLeft: isOpen ? drawerWidth : 57,
        width: isOpen ? `calc(100% - ${drawerWidth + 20}px)` : `calc(100% - 61px)`
      }}
      color="default"
      elevation={1}
      {...other}>
      <FontFaceLoader />
      <Toolbar
        disableGutters
        sx={{
          minHeight: 64,
          left: 0,
          px: 2,
          borderBottom: '1px solid #C4C4E5'
        }}>
        {/* Page title / nav */}
        <IconButton
          color="inherit"
          edge="start"
          sx={{
            ml: 1.5
          }}
          onClick={onDrawerToggle}>
          {isOpen ? <MenuFold /> : <MenuOpen />}
        </IconButton>

        <span style={{ flexGrow: 1 }} />

        {/* Account related controls (icon buttons) */}

        {user && (
          <Stack direction="row">
            <LanguageSelector
              tenantId={user.tenant_id}
              userId={user['user_id']}
              selectedLanguage={selectedTenantLanguage}
              setSelectedLanguage={setSelectedTenantLanguage}
            />
            <Button
              endIcon={<ExpandIcon sx={{ color: '#6F708E !important' }} />}
              size="small"
              ref={menuAnchorRef}
              onClick={openUserMenu}
              sx={{
                color: '#6F708E',
                mr: 6,
                '&:hover': {
                  backgroundColor: CustomPalette.Air
                }
              }}>
              <Chip
                icon={<AvatarIcon sx={{ fontSize: '18px', color: '#6F708E !important' }} />}
                label={getFirstName(user.username || '')}
                sx={{
                  color: '#151830',
                  fontFamily: 'BeausiteClassic-Regular',
                  fontSize: '16px',
                  mr: -1,
                  backgroundColor: CustomPalette.SecondaryCloud,
                  '&:hover': {
                    backgroundColor: CustomPalette.Air
                  }
                }}
              />
            </Button>

            <Button
              onClick={signOut}
              startIcon={<Logout />}
              sx={{
                mr: 1,
                ml: '6%',
                color: CustomPalette.PilotBlue,
                fontFamily: 'BeausiteClassic-Regular',
                fontSize: '16px'
              }}>
              {t('logout')}
            </Button>
          </Stack>
        )}
      </Toolbar>

      <LanguageMenu
        anchorEl={anchorEl.languageMenu}
        onClose={closeLanguageMenu}
        PaperProps={{ sx: { marginTop: '8px' } }}
      />
      <UserMenu
        anchorEl={anchorEl.userMenu}
        onClose={closeUserMenu}
        PaperProps={{ sx: { marginTop: '8px' } }}
        onChangeTheme={onChangeTheme}
      />
    </NavbarRoot>
  )
}

const getFirstName = (displayName: string): string => {
  return displayName && displayName.split(' ')[0]
}
