import CircularProgress from '@mui/material/CircularProgress'
import React, { FC, Suspense } from 'react'

import { Layout } from '../../../../common/components'
import { RouteResponse } from '../../../../core/router'
import { UserRoles } from '../../../../features/auth'
import { useHistory } from '../../../../hooks/useHistory'

type PrivateRouteProps = {
  route: RouteResponse
  roles: UserRoles[]
  tenantId: string
  handleChangeTheme: () => void
}

const userIsAuthorize = (authorize: string[], roles: string[]) => roles.some((role) => authorize?.includes(role))

export const PrivateRoute: FC<PrivateRouteProps> = ({ route, roles = [], handleChangeTheme }) => {
  const history = useHistory()
  const userRole = roles.find((role) => Object.values(UserRoles).includes(role)) || ''

  if (!userIsAuthorize(route.authorize, [userRole])) {
    history.push('/')
  }

  return (
    <Suspense fallback={<CircularProgress />}>
      <Layout onChangeTheme={handleChangeTheme}>{React.createElement(route?.component, route.props)}</Layout>
    </Suspense>
  )
}
