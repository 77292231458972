import { Button, Dialog, DialogContent, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { config as appConfig } from '../../../../core'
import { RootState, useTypedSelector } from '../../../../store'

import * as LoggedOutModalStyles from './LoggedOutModalStyles'

type LoggedOutModalProps = Record<string, never>

export const LoggedOutModal = (props: LoggedOutModalProps): JSX.Element => {
  const { t } = useTranslation('general', { useSuspense: false })
  const showLoggedOutModal = useTypedSelector((state: RootState) => state.authSlice.showLoggedOutModal)

  const onLoginButtonClick = React.useCallback((event) => {
    event.stopPropagation()
    event.preventDefault()

    window.location.assign(`${appConfig.api.origin}/login?redirect_url=${encodeURIComponent(window.location.href)}`)
  }, [])

  return (
    <Dialog
      aria-labelledby="responsive-dialog-title"
      disableEscapeKeyDown={true}
      maxWidth="xs"
      fullWidth
      open={showLoggedOutModal}
      sx={LoggedOutModalStyles.dialogStyles}>
      <DialogContent sx={LoggedOutModalStyles.dialogContentStyles}>
        <Typography color="#151830" component="div" fontSize={20} fontWeight={600} lineHeight="28px" variant="h6">
          {t('automatically_logged_out')}
        </Typography>
        <Typography component="p" sx={LoggedOutModalStyles.bodyStyles}>
          {t('logged_out_due_to_inactivity')}
          <br />
          {t('remember_to_log_out_when_not_using')}
        </Typography>
        <Button sx={LoggedOutModalStyles.goToLoginButtonStyles} onClick={onLoginButtonClick}>
          {t('go_to_login_page')}
        </Button>
      </DialogContent>
    </Dialog>
  )
}
