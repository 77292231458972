import * as React from 'react'
import { AppBarProps, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ProductFruits } from 'react-product-fruits'
import { useTranslation } from 'react-i18next'

import { UserData, useAuthUser, LoggedOutModal } from '../../features/auth'
import { AppToolBar, FullScreenProgress, Main, SideBar } from '../../common/components'

type LayoutProps = AppBarProps & {
  children: React.ReactNode
  onChangeTheme: () => void
}

const LayoutRoot = styled('div')(() => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 64
}))

const extractDomainFromEmail = (email: string) => {
  const atIndex = email.lastIndexOf('@')
  const dotIndex = email.indexOf('.', atIndex)

  return atIndex !== -1 && dotIndex !== -1 ? email.substring(atIndex + 1, dotIndex) : ''
}

export const Layout = (props: LayoutProps): JSX.Element => {
  const { onChangeTheme, children } = props
  const [open, setOpen] = React.useState(true)
  const user = useAuthUser()

  if (!user.token) {
    // Rendering placeholder page here while the user agent is redirected to
    // login.
    return <FullScreenProgress />
  }

  const subDomain = extractDomainFromEmail(user.token.email)
  const handleDrawerToggle = () => {
    setOpen(!open)
  }
  const userInfo = {
    username: user.username,
    role: user.roles[0],
    group: {
      groupId: subDomain + user.roles[0]
    }
  }
  const { i18n } = useTranslation('general')
  const languageCode = i18n.language.split('-')[0]

  const handleForceOpen = () => {
    setOpen(true)
  }

  return (
    <LayoutRoot>
      <ProductFruits workspaceCode="hqvCOgDUYr5cOK8F" language={languageCode} user={userInfo} />
      <AppToolBar isOpen={open} onDrawerToggle={handleDrawerToggle} onChangeTheme={onChangeTheme} />
      <SideBar isOpen={open} onDrawerToggle={handleDrawerToggle} handleForceOpen={handleForceOpen}></SideBar>
      <Box sx={{ display: 'flex', flex: '1 1 auto', flexDirection: 'column', width: '100%', backgroundColor: '#FFF' }}>
        <Main>{children}</Main>
      </Box>
      <LoggedOutModal />
    </LayoutRoot>
  )
}
