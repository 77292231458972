import { ReactNode } from 'react'

export interface TrainingData {
  courseId: string
  status: ReactNode
  participantTotal: ReactNode
  title: string
  time: string
  description: string
  button: string
  lastActive: string
}

export interface TrainingParticipationData {
  courseId: string
  trainingParticipantsId: string
  trainingCycleId: string
  trainingUserId: string
  trainingRecurringInterval: number
  startedAt?: string
  completedAt?: string
  trainingCode: string
  trainingStartDate?: string
  trainingEndDate?: string
  description: string
  button: string
  lastActive: string
  status: ReactNode
  participantTotal: ReactNode
  title: string
  time: string
  duration: number
  repeat: string
  coursePlayUrl?: string
}

export interface cardData {
  title: string
  date: string
}

export type MyCertificateSearchArgs = {
  tenantId: string
}

export type CertificateData = {
  id: string
  training_code: string
  training_internal_name: string
  obtained_on: string
  url: string
}

type Language = {
  language: string
  code: 'en-GB' | 'de-DE'
}

export const languages: Language[] = [
  { language: 'Deutsch', code: 'de-DE' },
  { language: 'English', code: 'en-GB' }
]
