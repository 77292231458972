import { useEffect } from 'react'

const FontFaceLoader = () => {
  const fontFace = `
  @font-face {
    font-family: 'BeausiteFit-Light';
    src: url('/fonts/BeausiteFit/BeausiteFit-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'BeausiteFit-Medium';
    src: url('/fonts/BeausiteFit/BeausiteFit-Medium.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
  }
  @font-face {
    font-family: 'BeausiteClassic-Medium';
    src: url('/fonts/BeausiteFit/BeausiteClassic-Medium.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
  }
  @font-face {
    font-family: 'BeausiteClassic-Regular';
    src: url('/fonts/BeausiteFit/BeausiteClassic-Regular.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
  }
  @font-face {
    font-family: 'BeausiteClassic-Regular';
    src: url('/fonts/BeausiteFit/BeausiteClassic-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'BeausiteClassic-Light';
    src: url('/fonts/BeausiteFit/BeausiteClassic-Light.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'BeausiteClassic-Medium';
    src: url('/fonts/BeausiteFit/BeausiteClassic-Medium.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'BeausiteClassic-Regular';
    src: url('/fonts/BeausiteFit/BeausiteClassic-Regular.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
  }
  
`

  useEffect(() => {
    const style = document.createElement('style')
    style.innerHTML = fontFace
    document.head.appendChild(style)

    return () => {
      document.head.removeChild(style)
    }
  }, [])

  return null
}

export default FontFaceLoader
