import { SearchTenantIcon } from '@/icons/SearchTenantIcon'
import { AppBarProps, ListItemIcon } from '@mui/material'
import { Home } from '../../../../../icons/Home'
import { HomeClose } from '../../../../../icons/HomeClose'

type TenantSearchIconProps = AppBarProps & {
  isOpen?: boolean
  icon: string
  isSidebarOpen: boolean
  isTenantSearch?: boolean
}

const defaultIcon = { open: Home, close: HomeClose }

const icons: Record<string, { open: React.ComponentType; close: React.ComponentType }> = {
  SearchTenantIcon: { open: SearchTenantIcon, close: SearchTenantIcon },
  default: defaultIcon
}

export const TenantSearchIcon = (props: TenantSearchIconProps): JSX.Element => {
  const { icon, isSidebarOpen } = props
  const IconComponent = icons[icon] || icons.default
  const Icon = isSidebarOpen ? IconComponent.open : IconComponent.close

  return (
    <ListItemIcon
      sx={{
        minWidth: 0
      }}>
      <Icon />
    </ListItemIcon>
  )
}
