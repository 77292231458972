import CustomPalette from '@/theme/CustomPalette'
import { tooltipClasses } from '@mui/material/Tooltip'
import { SystemStyleObject } from '@mui/system'
import { CSSProperties } from 'react'

export const boxStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  backgroundColor: CustomPalette.Air,
  height: '54px',
  width: '100%',
  pl: 2.5,
  cursor: 'pointer'
}

export const listItemButtonStyles = {
  display: 'block'
}

export const ListItemTextStyles = (isOpen: boolean) => ({
  '& span': {
    fontSize: '16px',
    fontFamily: 'BeausiteClassic-Regular',
    color: '#151830',
    fontWeight: '300',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: '.2rem'
  },
  opacity: isOpen ? 1 : 0,
  ml: '2.75rem'
})

export const menuStyles = {
  mt: '40px',
  ml: '-15px',
  '& .MuiPaper-root': {
    color: 'rgb(55, 65, 81)',
    width: '257px'
  }
}

export const searchFieldMenuItemStyles: SystemStyleObject = {
  pl: 2,
  height: '40px'
}

export const formControlStyles: SystemStyleObject = { ml: 0.5, width: '244px' }

export const selectStyles: SystemStyleObject = {
  border: 'none',
  height: '40px',
  '& fieldset': {
    border: 'none'
  }
}

const ITEM_HEIGHT = 100
const ITEM_PADDING_TOP = 10
export const menuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '260px',
      marginLeft: '-25px',
      borderRadius: '0 0 10px 10px',
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)'
    }
  },
  MenuListProps: {
    sx: { py: 0, borderRadius: '0 0 10px 10px' }
  },
  disableAutoFocusItem: true
}

export const divStickyStyles: CSSProperties = { position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 2 }

export const dividerStyles = { margin: '0px' }

export const nameTypographyStyles = {
  fontFamily: 'BeausiteClassic-Regular',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '19.2px',
  color: CustomPalette.NightBlue200,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  maxWidth: '225px'
}

export const menuItemStyles = {
  '&:hover': {
    backgroundColor: CustomPalette.AirLight
  },
  padding: '10px 15px'
}

export const slopStyles = {
  popper: {
    sx: {
      [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]: {
        marginLeft: '21px'
      }
    }
  }
}
