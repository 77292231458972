import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const Participants = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon style={{ fontSize: '20px' }} role="img" viewBox="0 0 17 17" {...props}>
      <path
        d="M0.75 15V14.25C0.75 11.3505 3.10051 9 6 9C8.8995 9 11.25 11.3505 11.25 14.25V15"
        stroke="#6F708E"
        stroke-width="1.2"
        stroke-linecap="round"
        fill="none"
      />
      <path
        d="M9.75 10.5C9.75 8.42895 11.429 6.75 13.5 6.75C15.571 6.75 17.25 8.42895 17.25 10.5V10.875"
        stroke="#6F708E"
        stroke-width="1.2"
        stroke-linecap="round"
        fill="none"
      />
      <path
        d="M6 9C7.65683 9 9 7.65683 9 6C9 4.34314 7.65683 3 6 3C4.34314 3 3 4.34314 3 6C3 7.65683 4.34314 9 6 9Z"
        stroke="#6F708E"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <path
        d="M13.5 6.75C14.7427 6.75 15.75 5.74264 15.75 4.5C15.75 3.25736 14.7427 2.25 13.5 2.25C12.2573 2.25 11.25 3.25736 11.25 4.5C11.25 5.74264 12.2573 6.75 13.5 6.75Z"
        stroke="#6F708E"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
    </SvgIcon>
  )
}
