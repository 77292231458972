import React, { ChangeEvent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'

import { GridProgress } from '../../common/components'
import FontFaceLoader from '../../theme/CustomFont'
import CustomPalette from '../../theme/CustomPalette'
import { DataListItem } from './DataListItem/DataListItem'

export type DataListProps<T> = {
  data: T
  titles: string[]
  emptyList: string
  useSearchList?: (arg: T) => unknown
}

export const DataList = <T extends Record<string, unknown>>({
  titles,
  emptyList,
  useSearchList
}: DataListProps<T>): JSX.Element => {
  const { data: list = [], isFetching, isUninitialized } = useSearchList()
  const isLoading = isFetching || isUninitialized

  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(0)

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as unknown as number
    setLimit(value)
  }

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }
  const { t, i18n } = useTranslation('general')

  return useMemo(
    () => (
      <GridProgress loading={isLoading} key={i18n.language}>
        <FontFaceLoader />
        <Box sx={{ minWidth: 1050, mt: 3 }}>
          <TableContainer sx={{ borderRadius: '10px' }}>
            <Table>
              <TableHead sx={{ backgroundColor: '#f8f8fa', borderBottom: '0px solid' }}>
                <TableRow>
                  {titles?.map((title) => (
                    <TableCell
                      key={title.toString()}
                      sx={{
                        color: CustomPalette.NightBlueLight,
                        fontWeight: '600',
                        fontSize: '16px'
                      }}>
                      {title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!list.length ? (
                  <TableRow>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}>
                        <Typography color="textPrimary" variant="body1">
                          {emptyList}
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  list?.slice(0, limit).map((object: T) => (
                    <TableRow hover color="inherit" key={object.id}>
                      <TableCell>
                        <DataListItem
                          data={object}
                          objectType={object.code ? `${object.code}` : `${object.id}`}
                          nameItem={object.code ? `${object.code} - ${object.name}` : `${object.name}`}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Stack direction="row">
          <Typography sx={{ fontSize: '14px', mt: '7px', pl: '11px' }}>
            {`${t('item1')}`} {list.length} {`${t('item2')}`}{' '}
          </Typography>
          <Pagination
            count={list.length}
            onChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            labelRowsPerPage={t('rowsPerPage')}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25]}
            shape="rounded"
          />
        </Stack>
      </GridProgress>
    ),
    [isLoading, list, i18n.language]
  )
}
