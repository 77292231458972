import { sessionCheck, setupTenantByEmail } from '@/core/tenant'
import React from 'react'

export const createTenantUrl = (subdomain: string, email: string, appUrl: string): string => {
  const { location } = window
  const tenantUrl = new URL(`${location.protocol}//${subdomain}.${appUrl}`)
  tenantUrl.username = email
  tenantUrl.searchParams.append('username', email)
  return tenantUrl.href
}

export const useSessionCheck = (appUrl: string, setSessionActive: React.Dispatch<React.SetStateAction<boolean>>) => {
  React.useEffect(() => {
    const sessionCheckAsync = async () => {
      const session = await sessionCheck()
      if (session && !('statusText' in session)) {
        const { email } = session.token
        const tenant = await setupTenantByEmail(email)
        if ('subdomain' in tenant) {
          window.location.replace(createTenantUrl(tenant.subdomain, email, appUrl))
        }
      } else {
        setSessionActive(false)
      }
    }
    sessionCheckAsync()
  }, [appUrl, setSessionActive])
}
