import React from 'react'
import { Box, Grid, Stack } from '@mui/material'

type CardGridStackProps = {
  items: JSX.Element[]
  xs: number
  sm: number
}

export const CardGridStack = ({ items, xs, sm }: CardGridStackProps): JSX.Element => {
  const getGridSize = (index: number): number => {
    const totalItems = items.length

    // If there's only one item, make it full width.
    if (totalItems === 1) return 12

    // If there are two items, divide the row into halves.
    if (totalItems === 2) return 6

    // If there are three or more items, create a 3x3 grid.
    return 4
  }

  return (
    <Box>
      <Grid container spacing={3}>
        {items.map((item, index) => (
          <Grid
            item
            xs={xs}
            sm={sm}
            md={getGridSize(index)}
            lg={getGridSize(index)}
            xl={getGridSize(index)}
            key={index}>
            <Stack
              sx={{
                ml: 2,
                mb: 2.5,
                width: '100%',
                height: '100%',
                flexDirection: 'column',
                boxShadow: 'none',
                borderRadius: 4,
                bgcolor: '#F1F1FF',
                border: '1px solid #DADAFC',
                transition: '0.5s ease',
                '&:hover': {
                  bgcolor: '#fff'
                }
              }}>
              {item}
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
