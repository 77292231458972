import { Alert } from '@mui/material'
import Snackbar from '@mui/material/Snackbar'
import { SystemStyleObject } from '@mui/system'

type Props = {
  open: boolean
  styles?: SystemStyleObject
  onClose?: () => void
  message: string | undefined
  severity: 'error' | 'warning' | 'info' | 'success' | undefined
  autoHideDuration?: number
  anchorOrigin?: {
    vertical: 'top' | 'bottom'
    horizontal: 'left' | 'center' | 'right'
  }
}

const CustomSnackbar = ({
  open,
  onClose,
  message,
  severity = 'info' as 'info' | 'error' | 'warning' | 'success',
  autoHideDuration = 5000,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center'
  },
  styles = {},
  ...props
}: Props) => {
  return (
    <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={onClose} anchorOrigin={anchorOrigin} {...props}>
      <Alert onClose={onClose} severity={severity} sx={{ ...styles }}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default CustomSnackbar
