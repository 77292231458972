import { config as appConfig } from '@/core'

export function debounce<T extends unknown[], U>(callback: (...args: T) => PromiseLike<U> | U, wait: number) {
  let timer: ReturnType<typeof setTimeout>

  return (...args: T): Promise<U> => {
    clearTimeout(timer)
    return new Promise((resolve) => {
      timer = setTimeout(() => resolve(callback(...args)), wait)
    })
  }
}

export const getAmplitudeApiKey = () => {
  switch (appConfig.app.env) {
    case 'local':
      return process.env.AMPLITUDE_LOCAl_API_KEY
    case 'test':
      return process.env.AMPLITUDE_STAGE_API_KEY
    case 'production':
      return process.env.AMPLITUDE_PROD_API_KEY
    default:
      console.error('Unrecognized Amplitude Environment')
  }
}
