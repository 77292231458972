import { Button } from '@mui/material'
import * as React from 'react'

import CustomPalette from '@/theme/CustomPalette'

type LanguageButtonProps = {
  language: string
  languageCode: 'en-GB' | 'de-DE'
  selectedLanguage: string
  onClick: (languageCode: 'en-GB' | 'de-DE') => void
}

const LanguageButton: React.FC<LanguageButtonProps> = ({ language, languageCode, selectedLanguage, onClick }) => {
  return (
    <Button
      href="#"
      onClick={() => onClick(languageCode)}
      sx={{
        color: '#463ED7',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        backgroundColor: selectedLanguage === languageCode ? CustomPalette.SecondaryNightBlue100 : 'transparent',
        borderRadius: '20px',
        ':hover': {
          backgroundColor: CustomPalette.SecondaryNightBlue50,
          borderRadius: '20px'
        }
      }}>
      {language}
    </Button>
  )
}

export default LanguageButton
