import { useTranslation } from 'react-i18next'
import { ListItemButton, ListItemText } from '@mui/material'
import ListItem from '@mui/material/ListItem'

import { NavItem } from '@/common/components'
import {
  listItemButtonStyles,
  listItemTextStyles
} from '@/common/components/SideBar/SidebarComponents/NavBar/NavBarStyles'
import { ITenantState } from '@/features/auth/types'
import { useLocation, useNavigate } from '@/hooks'

interface INavBarMenuItemProps {
  isNavBarOpen: boolean
  path: string
  isTenantSearch?: boolean
  activeTenant?: ITenantState
  menu: { icon: string; label: string }
  handleTenantItemClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
}
const NavBarMenuItem = ({
  isNavBarOpen,
  isTenantSearch = false,
  path,
  menu,
  handleTenantItemClick,
  activeTenant = undefined
}: INavBarMenuItemProps) => {
  const { t } = useTranslation('general')
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <ListItem disablePadding sx={{ display: 'block' }}>
      <ListItemButton
        sx={listItemButtonStyles(isNavBarOpen, isTenantSearch)}
        href={path}
        selected={isTenantSearch ? !!activeTenant?.id : location.pathname.includes(path)}
        onClick={isTenantSearch ? handleTenantItemClick : navigate}>
        {menu?.icon && !isTenantSearch && (
          <NavItem isOpen={isNavBarOpen} icon={menu?.icon} isSidebarOpen={isNavBarOpen} />
        )}
        <ListItemText
          primary={isTenantSearch ? activeTenant?.name || 'No Tenant Selected' : t(menu?.label)}
          sx={listItemTextStyles(isNavBarOpen)}
        />
        {isTenantSearch && (
          <NavItem isOpen={isNavBarOpen} icon="SearchTenantIcon" isTenantSearch={true} isSidebarOpen={isNavBarOpen} />
        )}
      </ListItemButton>
    </ListItem>
  )
}

export default NavBarMenuItem
