import { TenantSearchFormValues } from '@/features/tenant'
import { Search as SearchIcon } from '../../../../icons'
import { Box, InputAdornment, SvgIcon, TextField } from '@mui/material'
import { useFormikContext } from 'formik'
import React, { FC, useCallback, useMemo } from 'react'
import { tenantSearchFormDefaultValues } from './TenantSearchFormContext'

type SearchBarProps = {
  placeholder: string
}

export const TenantSearch: FC<SearchBarProps> = ({ placeholder }) => {
  const { values, handleChange, touched, setFieldValue } = useFormikContext<TenantSearchFormValues>()

  const handleChangeAndResetPage = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleChange(e)
      setFieldValue('page', tenantSearchFormDefaultValues.page)
    },
    [handleChange, setFieldValue]
  )

  return useMemo(
    () => (
      <Box sx={{ width: '400px' }}>
        <TextField
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon color="action" fontSize="small">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
            sx: {
              height: '40px',
              padding: '10px 14px'
            }
          }}
          placeholder={placeholder}
          variant="outlined"
          name="name"
          value={values.name}
          onChange={handleChangeAndResetPage}
          error={touched.name}
          helperText={touched.name}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: 3
            }
          }}
        />
      </Box>
    ),
    [values, handleChange, touched, handleChangeAndResetPage]
  )
}
