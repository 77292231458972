import { createSlice } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { AdministrationState } from './types'

const initialState: AdministrationState = {
  openCreateManagerMenu: false,
  openDeleteManagerMenu: false,
  openUpdateManagerMenu: false,
  openResentInvitationMenu: { isOpen: false, email: '' },
  setUpdateManagerContent: false
}

export const administrationSlice = createSlice({
  name: 'administrationSlice',
  initialState,
  reducers: {
    openCreateManagerMenu(state, action) {
      state.openCreateManagerMenu = action.payload
    },
    openUpdateManagerMenu(state, action) {
      state.openUpdateManagerMenu = action.payload
    },
    setUpdateManagerContent(state, action) {
      state.setUpdateManagerContent = action.payload
    },
    openDeleteManagerMenu(state, action) {
      state.openDeleteManagerMenu = action.payload
    },
    setNotifications(state, action) {
      state.setNotifications = action.payload
    },
    openResentInvitationMenu(state, action) {
      state.openResentInvitationMenu = action.payload
    }
  }
})

export const administrationReducer = persistReducer(
  {
    key: 'rtk:tenant',
    storage,
    whitelist: ['']
  },
  administrationSlice.reducer
)
