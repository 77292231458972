import { Box, CircularProgress, Grid, GridProps } from '@mui/material'
import React from 'react'

type GridProgressProps = {
  loading: boolean
} & GridProps

export const GridProgress = ({ loading, children, ...gridProps }: GridProgressProps): JSX.Element => {
  return (
    <Grid {...gridProps}>
      {loading ? (
        <Box display="flex" alignItems="center" justifyContent="center" height={200} width="100%">
          <CircularProgress />
        </Box>
      ) : (
        <>{children}</>
      )}
    </Grid>
  )
}
