import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { SvgIconProps } from '@mui/material'
import React from 'react'

const ExpandIcon = (props: SvgIconProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [open, setOpen] = React.useState(false)
  const handleClick = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    if (props.onClick) {
      props.onClick(event)
    }
  }
  return open ? (
    <ExpandLessIcon
      {...props}
      onClick={handleClick}
      sx={{
        ml: 1,
        fontSize: '16px',
        color: '#151830 !important'
      }}
    />
  ) : (
    <ExpandMoreIcon
      {...props}
      onClick={handleClick}
      sx={{
        fontSize: '16px',
        color: '#151830 !important'
      }}
    />
  )
}

export default ExpandIcon
