import React from 'react'
import { Formik } from 'formik'
import { FC, ReactNode } from 'react'

export const courseSearchFormDefaultValues = {
  name: '',
  type: '',
  sort: '', //BEST_MATCH,
  per_page: 5,
  page: 1
}

type CourseSearchFormContextProps = {
  children: ReactNode
}

export const CourseSearchFormContext: FC<CourseSearchFormContextProps> = ({ children }) => {
  return (
    <Formik initialValues={courseSearchFormDefaultValues} onSubmit={() => undefined}>
      {children}
    </Formik>
  )
}
