import { SvgIcon, SvgIconProps } from '@mui/material'
import * as React from 'react'

export const VisibilityIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon role="img" viewBox="0 0 18 19" {...props}>
      <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9 11.2C9.82843 11.2 10.5 10.5284 10.5 9.70001C10.5 8.87159 9.82843 8.20001 9 8.20001C8.17157 8.20001 7.5 8.87159 7.5 9.70001C7.5 10.5284 8.17157 11.2 9 11.2Z"
          stroke="#6B58F6"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.75 9.70001C14.3336 11.9432 11.7888 14.2 9.00001 14.2C6.21127 14.2 3.66642 11.9432 2.25 9.70001C3.97392 7.5687 5.99372 5.20001 9.00001 5.20001C12.0063 5.20001 14.0261 7.56866 15.75 9.70001Z"
          stroke="#6B58F6"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}
