import { GridProgress } from '@/common/components'
import { useLoadViews } from '@/hooks'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { MenusNavItem } from '@/menus'
import CustomPalette from '@/theme/CustomPalette'
import { Course } from '../../types'
import { CourseListItem } from '../CourseListItem/CourseListItem'

export type DataListProps = {
  data: Course
  routeLabel: string
  titles: string[]
  emptyList: string
  selectedCourse?: Course
  menus?: MenusNavItem[]
  openAction: (courseCode: string) => void
  closeAction?: () => void
  useSearchList?: (arg: Course) => unknown
}

export const CourseList = (props: DataListProps): JSX.Element => {
  const { data: list = [], isFetching, isUninitialized } = props.useSearchList()
  const isLoading = isFetching || isUninitialized
  const { t } = useTranslation('general')

  const [Menus, setMenus] = useState<Array<JSX.Element>>([])

  useEffect(() => {
    const loadViews = async () => {
      return await useLoadViews({
        views: props.menus,
        action: props.closeAction,
        ...props.selectedCourse
      })
    }
    loadViews().then(setMenus)
  }, [props.menus])

  return (
    <>
      <GridProgress loading={isLoading} sx={{ pb: 5 }}>
        <Box sx={{ minWidth: 1050 }}>
          <TableContainer>
            <Table>
              <TableHead sx={{ backgroundColor: CustomPalette.SecondaryCloud }}>
                <TableRow sx={{ '& .MuiTableCell-root': { paddingLeft: 0, border: 'none' } }}>
                  <TableCell sx={{ visibility: 'none', width: 0 }}></TableCell>
                  {props.titles?.map((title) => (
                    <TableCell key={title.toString()}>{title}</TableCell>
                  ))}
                  <TableCell></TableCell>
                  <TableCell sx={{ visibility: 'none', width: 0 }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!list.length ? (
                  <TableRow>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}>
                        <Typography color="textPrimary" variant="body1">
                          {props.emptyList}
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  list?.map((course: Course) => (
                    <CourseListItem
                      data={course}
                      objectType={course.code ? `${course.code}` : `${course.id}`}
                      nameItem={course.code ? `${course.code} - ${course.name}` : `${course.name}`}
                      routeLabel={props.routeLabel}
                      openUpdateMenu={props.openAction}
                    />
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {Menus}
      </GridProgress>
    </>
  )
}
