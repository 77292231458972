import { debounce } from '@/core'
import { tenantApi } from '@/services/api/tenant/api'
import { useTypedDispatch } from '@/store'
import { useCallback, useEffect, useMemo } from 'react'

import { MyCertificateSearchArgs } from '../types'

export const useSearchArgs = (tenantId: string): MyCertificateSearchArgs => {
  return useMemo<MyCertificateSearchArgs>(() => {
    return {
      tenantId
    }
  }, [])
}

export const useSearchState = (tenantId: string) => {
  const searchArgs = useSearchArgs(tenantId)
  return tenantApi.endpoints.certificatesGetCurrentUserCertificatesTenantsTenantIdMeCertificatesGet.useQueryState(
    searchArgs
  )
}

export const useSearch = (tenantId: string) => {
  const dispatch = useTypedDispatch()
  const searchArgs = useSearchArgs(tenantId)

  const searchFn = useCallback(
    (args: typeof searchArgs) => {
      dispatch(
        tenantApi.endpoints.certificatesGetCurrentUserCertificatesTenantsTenantIdMeCertificatesGet.initiate(args)
      )
    },
    [dispatch]
  )
  const debouncedSearchFn = useMemo(
    () =>
      debounce((args: typeof searchArgs) => {
        searchFn(args)
      }, 100),

    [searchFn]
  )

  useEffect(() => {
    searchFn(searchArgs)
  }, [])

  useEffect(() => {
    debouncedSearchFn(searchArgs)
  }, [searchArgs, debouncedSearchFn])

  return useSearchState(tenantId)
}
