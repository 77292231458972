import { MenuItem, TextField } from '@mui/material'
import { FC } from 'react'
import { searchFieldMenuItemStyles } from './TenantSearchItemStyles'

export const TenantSearchField: FC<{
  searchTerm: string
  handleSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  textFieldRef: React.RefObject<HTMLInputElement>
}> = ({ searchTerm, handleSearchChange, textFieldRef }) => (
  <MenuItem sx={searchFieldMenuItemStyles} onClick={(e) => e.stopPropagation()} onKeyDown={(e) => e.stopPropagation()}>
    <TextField
      autoFocus
      placeholder="Search..."
      type="text"
      fullWidth
      variant="standard"
      value={searchTerm}
      onChange={handleSearchChange}
      InputProps={{
        disableUnderline: true
      }}
      inputRef={textFieldRef}
    />
  </MenuItem>
)
