export const appBarStyles = {
  top: 0,
  left: 0,
  zIndex: 999999,
  backgroundColor: '#F8F8FA'
}

export const toolBarStyles = {
  display: 'flex',
  justifyContent: 'space-between'
}

export const downloadButtonStyles = {
  position: 'fixed',
  top: '82px',
  right: '24px',
  height: '40px',
  padding: '0px 24px',
  backgroundColor: '#D7D9F7',
  border: 'none',
  borderRadius: '20px',
  color: '#ffffff',
  '&:hover': {
    backgroundColor: '#F8F8FA'
  }
}
