import { config as appConfig } from '../../../core'
import { setCurrentUser, UserData } from '../../../features/auth'
import { createApi } from '@reduxjs/toolkit/query/react'
import Cookies from 'js-cookie'
import { authCustomFetchBase } from '../mutexCustomFetchBase'

const getAuthCookies = {
  accessToken: Cookies.get('access_token'),
  refreshToken: Cookies.get('refresh_token')
}

export const AUTH_API_REDUCER_KEY = 'authApi'
export const authApi = createApi({
  reducerPath: AUTH_API_REDUCER_KEY,
  baseQuery: authCustomFetchBase,

  tagTypes: ['Auth'],
  endpoints: (builder) => ({
    signOut: builder.query({
      query: () => {
        return `${appConfig.api.origin}/logout`
      }
    }),
    getUser: builder.query({
      query: () => {
        return 'user'
      },
      transformResponse: (result) => {
        return result
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setCurrentUser(data as UserData))
        } catch (error) {
          console.error(error)
        }
      }
    })
  }),
  refetchOnMountOrArgChange: 60
})
