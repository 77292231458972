import * as amplitude from '@amplitude/analytics-browser'
import { useEffect } from 'react'

export function usePageVisited(pageName: string) {
  useEffect(() => {
    amplitude.track(`${pageName} Page Visited`, {
      hostname: window.location.hostname,
      pathname: window.location.pathname,
      location: window.location.href,
      title: document.title
    })
  }, [])
}
