import administration from '../features/administration'
import courses from '../features/course'
import my from '../features/my'
import participants from '../features/participants'
import tenants from '../features/tenant'
import trainings from '../features/trainings'
import Home from './Home'
import home from '../features/home'

/**
 * The list of application routes (pages).
 */
export default [
  Home,
  ...(tenants || []),
  ...(courses || []),
  ...(home || []),
  ...(administration || []),
  ...(participants || []),
  ...(trainings || []),
  ...(my || [])
] as const
