import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const HeadSetClose = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon style={{ fontSize: '26px' }} role="img" viewBox="0 0 24 24" {...props}>
      <path
        d="M20 11C20 6.58172 16.4183 3 12 3C7.58172 3 4 6.58172 4 11"
        stroke="#151830"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <path
        d="M2 15.4382V13.5614C2 12.6436 2.62459 11.8437 3.51493 11.6211L5.25448 11.1862C5.63317 11.0915 6 11.3779 6 11.7683V17.2313C6 17.6217 5.63317 17.9081 5.25448 17.8134L3.51493 17.3785C2.62459 17.1559 2 16.356 2 15.4382Z"
        stroke="#151830"
        stroke-width="1.5"
        fill="none"
      />
      <path
        d="M22 15.4382V13.5614C22 12.6436 21.3754 11.8437 20.4851 11.6211L18.7455 11.1862C18.3668 11.0915 18 11.3779 18 11.7683V17.2313C18 17.6217 18.3668 17.9081 18.7455 17.8134L20.4851 17.3785C21.3754 17.1559 22 16.356 22 15.4382Z"
        stroke="#151830"
        stroke-width="1.5"
        fill="none"
      />
      <path d="M20 18V18.5C20 19.6046 19.1046 20.5 18 20.5H14.5" stroke="#151830" stroke-width="1.5" />
      <path
        d="M13.5 22H10.5C9.67157 22 9 21.3284 9 20.5C9 19.6716 9.67157 19 10.5 19H13.5C14.3284 19 15 19.6716 15 20.5C15 21.3284 14.3284 22 13.5 22Z"
        stroke="#151830"
        stroke-width="1.5"
        fill="none"
      />
    </SvgIcon>
  )
}
