import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { AuthState, UserData } from './types'

const initialState: AuthState = {
  tenant: {
    id: '',
    name: '',
    default_language_tag: '',
    details: '',
    odoo_partner_id: '',
    subdomain: '',
    logo_url: '',
    theme_color: ''
  },
  showLoggedOutModal: false
}

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setCurrentTenant(state, action) {
      state.tenant = action.payload
    },
    setCurrentUser(state, action: PayloadAction<UserData | undefined>) {
      state.user = action.payload
    },
    setTenantLogo(state, action: PayloadAction<string>) {
      state.tenant.logo_url = action.payload
    },
    setTenantThemeColor(state, action: PayloadAction<string>) {
      state.tenant.theme_color = action.payload
    },
    signOut: () => initialState,
    showLoggedOutModal(state) {
      state.showLoggedOutModal = true
    }
  }
})

export const { signOut, setCurrentUser, setCurrentTenant, showLoggedOutModal, setTenantLogo, setTenantThemeColor } =
  authSlice.actions

export const authReducer = persistReducer(
  {
    key: 'rtk:auth',
    storage,
    whitelist: ['session']
  },
  authSlice.reducer
)
