import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const HeadSetOpen = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon style={{ fontSize: '20px' }} role="img" viewBox="0 0 20 20" {...props}>
      <path
        d="M16.6666 9.16667C16.6666 5.48477 13.6818 2.5 9.99992 2.5C6.31802 2.5 3.33325 5.48477 3.33325 9.16667"
        stroke="#6F708E"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <path
        d="M1.66675 12.8652V11.3012C1.66675 10.5363 2.18724 9.86974 2.92919 9.68424L4.37881 9.32182C4.69439 9.24291 5.00008 9.48157 5.00008 9.80691V14.3594C5.00008 14.6847 4.69439 14.9234 4.37881 14.8445L2.92919 14.4821C2.18724 14.2966 1.66675 13.63 1.66675 12.8652Z"
        stroke="#6F708E"
        stroke-width="1.2"
        fill="none"
      />
      <path
        d="M18.3333 12.8652V11.3012C18.3333 10.5363 17.8128 9.86974 17.0709 9.68424L15.6213 9.32182C15.3057 9.24291 15 9.48157 15 9.80691V14.3594C15 14.6847 15.3057 14.9234 15.6213 14.8445L17.0709 14.4821C17.8128 14.2966 18.3333 13.63 18.3333 12.8652Z"
        stroke="#6F708E"
        stroke-width="1.2"
        fill="none"
      />
      <path
        d="M16.6666 15V15.4167C16.6666 16.3372 15.9204 17.0833 14.9999 17.0833H12.0833"
        stroke="#6F708E"
        stroke-width="1.2"
        fill="none"
      />
      <path
        d="M11.25 18.3335H8.75C8.05964 18.3335 7.5 17.7738 7.5 17.0835C7.5 16.3932 8.05964 15.8335 8.75 15.8335H11.25C11.9403 15.8335 12.5 16.3932 12.5 17.0835C12.5 17.7738 11.9403 18.3335 11.25 18.3335Z"
        stroke="#6F708E"
        stroke-width="1.2"
        fill="none"
      />
    </SvgIcon>
  )
}
