import { useEffect } from 'react'
import { AppBarProps, Drawer, Link } from '@mui/material'
import { CSSObject, Theme, styled, useTheme } from '@mui/material/styles'

import { TenantSearchFormContext } from '@/features/tenant/components/TenantSearch/TenantSearchFormContext'
import { CompanyLogo, NavBar } from '../../../common/components'
import { useNavigate } from '../../../hooks'
import CustomPalette from '../../../theme/CustomPalette'
import { CompanyLogoSmall } from '../AppToolBar/Components/CompanyLogo/CompanyLogoSmall'
import { RootState, useTypedDispatch, useTypedSelector } from '@/store'
import { useTenantThemesGetThemeTenantsTenantIdThemeGetQuery } from '@/services/api/tenant/tenants'
import { authSlice } from '@/features/auth'
import ImageToSvgConverter from '../ImageToSvgConverter/ImageToSvgConverter'

type SideBarProps = AppBarProps & {
  isOpen?: boolean
  isSidebarOpen?: boolean
  onDrawerToggle: () => void
  handleForceOpen: () => void
}

const drawerWidth = 260

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(5.5)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7.5)} + 1px)`
  }
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar
}))

export const SideBar = (props: SideBarProps): JSX.Element => {
  const { isOpen, onDrawerToggle, handleForceOpen } = props
  const theme = useTheme()
  const navigate = useNavigate()
  const breakpointWidth = 1468

  const dispatch = useTypedDispatch()
  const accessedTenant = useTypedSelector((state: RootState) => state.authSlice.tenant)

  const { data: tenantTheme, refetch } = useTenantThemesGetThemeTenantsTenantIdThemeGetQuery(
    {
      tenantId: accessedTenant?.id
    },
    {
      refetchOnMountOrArgChange: true
    }
  )

  useEffect(() => {
    if (tenantTheme) {
      dispatch(authSlice.actions.setTenantLogo(tenantTheme?.logo_url || ''))
      dispatch(authSlice.actions.setTenantThemeColor(tenantTheme.color || ''))
    }
  }, [refetch, tenantTheme])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < breakpointWidth && isOpen) {
        onDrawerToggle()
      } else if (window.innerWidth >= breakpointWidth && !isOpen) {
        onDrawerToggle()
      }
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [isOpen, onDrawerToggle])

  return (
    <Drawer
      anchor="left"
      variant="permanent"
      color="default"
      data-testid="drawer"
      open={isOpen}
      onClose={onDrawerToggle}
      ModalProps={{
        keepMounted: true
      }}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(isOpen && {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': {
            ...openedMixin(theme),
            backgroundColor: CustomPalette.SecondaryCloud,
            borderRight: `1px solid #C4C4E5`
          }
        }),
        ...(!isOpen && {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': {
            ...closedMixin(theme),
            backgroundColor: CustomPalette.SecondaryCloud,
            borderRight: `1px solid #C4C4E5`
          }
        })
      }}>
      <DrawerHeader sx={{ mt: 1, ml: accessedTenant.logo_url ? 0 : 1, mb: 6 }} data-testid="drawer-header">
        <Link
          color="inherit"
          underline="none"
          href="/"
          onClick={navigate}
          sx={{ margin: accessedTenant.logo_url ? 'auto' : 0 }}>
          {isOpen ? (
            accessedTenant.logo_url ? (
              <ImageToSvgConverter imageString={accessedTenant.logo_url} />
            ) : (
              <CompanyLogo />
            )
          ) : accessedTenant.logo_url ? (
            <ImageToSvgConverter imageString={accessedTenant.logo_url} />
          ) : (
            <CompanyLogoSmall />
          )}
        </Link>
      </DrawerHeader>

      <TenantSearchFormContext>
        <NavBar isOpen={isOpen} handleForceOpen={handleForceOpen} />
      </TenantSearchFormContext>
    </Drawer>
  )
}
