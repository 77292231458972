import { useCallback, useEffect, useMemo } from 'react'
import { parseTemplate } from 'url-template'

import { debounce } from '../../../core'
import { CourseSearchArgs } from '@/features/course'
import { tenantApi } from '../../../services/api/tenant/api'
import { useTypedDispatch } from '../../../store'
import { useCourseSearchFormContext } from './useCourseSearchFormContext'

const searchQs = parseTemplate('{name}')

export const useSearchCoursesArgs = (): CourseSearchArgs => {
  const { values } = useCourseSearchFormContext()

  return useMemo<CourseSearchArgs>(() => {
    return {
      query: decodeURIComponent(
        searchQs.expand({
          name: values.name && `${values.name}`
        })
      ).trim()
      // sort: values.sort,
      // per_page: values.per_page,
      // page: values.page,
    }
  }, [values])
}

export const useSearchCoursesState = () => {
  const searchArgs = useSearchCoursesArgs()
  return tenantApi.endpoints.getCoursesCoursesGet.useQueryState(searchArgs)
}

export const useSearchCourses = () => {
  const dispatch = useTypedDispatch()
  const searchArgs = useSearchCoursesArgs()

  const tenantSearchFn = useCallback(
    (args: typeof searchArgs) => {
      dispatch(tenantApi.endpoints.getCoursesCoursesGet.initiate(args))
    },
    [dispatch]
  )
  const debouncedCourseSearchFn = useMemo(
    () =>
      debounce((args: typeof searchArgs) => {
        tenantSearchFn(args)
      }, 100),

    [tenantSearchFn]
  )

  useEffect(() => {
    tenantSearchFn(searchArgs)
  }, [])

  useEffect(() => {
    debouncedCourseSearchFn(searchArgs)
  }, [searchArgs, debouncedCourseSearchFn])

  return useSearchCoursesState()
}
