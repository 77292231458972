import { config as appConfig } from '@/core'
import { UserData } from '@/features/auth'
import type { CustomError, TenantLogin } from './types'

export const tenantsCheck = async (email: string): Promise<TenantLogin | CustomError> => {
  try {
    const response = await fetch(`${appConfig.api.origin}/tenants/login_info?email=${encodeURIComponent(email)}`)
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }

    const data = await response.json()
    return data as TenantLogin
  } catch (error) {
    console.error('Error:', error)
    return {
      status: 404,
      statusText: 'Tenant not found.'
    } as CustomError
  }
}

export const subdomainCheck = async (url: URL): Promise<unknown | CustomError> => {
  const domain = new URL(url).hostname.replace('www.', '')
  if ((domain.split('.') || []).length > 2) {
    const subdomain = domain.split('.')[0]

    return fetch(`${appConfig.api.origin}/subdomain/${subdomain}`)
      .then((response): unknown => {
        return response.json()
      })
      .catch((error: CustomError) => {
        return error
      })
  }

  return {
    status: 404,
    statusText: 'Please enter a subdomain name.'
  } as CustomError
}

export const setupTenant = async (url: URL): Promise<unknown | CustomError> => {
  try {
    const tenant = await subdomainCheck(url)

    if (tenant instanceof Error) {
      throw tenant
    }
    return tenant
  } catch (error: unknown) {
    return error as CustomError
  }
}

export const setupTenantByEmail = async (email: string): Promise<TenantLogin | CustomError> => {
  try {
    const tenant = await tenantsCheck(email)

    if (tenant instanceof Error) {
      throw tenant
    }
    return tenant
  } catch (error: unknown) {
    return error as CustomError
  }
}

export const sessionCheck = async (): Promise<UserData | CustomError> => {
  try {
    const response = await fetch(`${appConfig.api.origin}/user`, {
      method: 'GET',
      credentials: 'include'
    })

    if (!response.ok) {
      return {
        status: 500,
        statusText: 'Internal server error.'
      } as CustomError
    }

    const data = await response.json()
    return data as UserData
  } catch (error) {
    console.error('Error:', error)
    return {
      status: 404,
      statusText: 'Session not found.'
    } as CustomError
  }
}
