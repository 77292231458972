import { withStyles } from '@mui/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import CustomPalette from '@/theme/CustomPalette'

export const EmptyListBoxStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%'
}

export const TitleTypographyH4Style = {
  fontSize: '20px',
  fontWeight: 600,
  fontFamily: 'BeausiteClassic-Regular',
  color: CustomPalette.NightBlue200,
  margin: '25px 0 20px 0'
}

export const TitleTypographyBodyStyle = {
  fontSize: '16px',
  fontWeight: 600,
  fontFamily: 'BeausiteClassic-Regular',
  color: CustomPalette.NightBlue200
}

export const InputLabelStyle = {
  color: CustomPalette.SecondaryNightBlue900,
  paddingBottom: '6px',
  width: '356px',
  fontSize: '16px',
  fontFamily: 'BeausiteClassic-Regular'
}

export const MenuPaperStyle = {
  width: 250,
  backgroundColor: CustomPalette.SecondaryCloud,
  borderColor: CustomPalette.SecondaryNightBlue200,
  borderRadius: '0 0 10px 10px',
  boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
}

export const SelectTypographyStyle = {
  color: CustomPalette.SecondaryNightBlue600,
  fontSize: '16px',
  fontWeight: '400',
  opacity: 0.42
}

export const SelectStyle = (isClosed: boolean) => ({
  width: '505px',
  height: '48px',
  border: 1,
  borderRadius: '10px',
  borderBottomLeftRadius: isClosed ? '10px' : '0px',
  borderBottomRightRadius: isClosed ? '10px' : '0px',
  boxShadow: isClosed ? 'none' : `0px 0px 0px 2px ${CustomPalette.PilotBlueShadow}`,
  borderColor: isClosed ? CustomPalette.SecondaryNightBlue200 : CustomPalette.SecondaryNightBlue900,
  margin: 'auto 0',
  paddingRight: '8px',
  '&:hover': {
    borderColor: CustomPalette.SecondaryNightBlue900
  },
  '&:focus-within': {
    borderColor: CustomPalette.SecondaryNightBlue900,
    boxShadow: `0px 0px 0px 2px ${CustomPalette.PilotBlueShadow}`
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
})

export const StyledExpandMoreIcon = withStyles({
  root: {
    color: CustomPalette.SecondaryNightBlue900
  }
})(ExpandMoreIcon)

export const EditVariantCancelButtonStyle = {
  backgroundColor: 'transparent',
  color: CustomPalette.PilotBlue,
  fontSize: '16px',
  fontWeight: 400,
  '&:hover': {
    backgroundColor: 'transparent'
  }
}

export const VariantParametersCancelButtonStyle = {
  width: '44px',
  marginLeft: '-7px',
  marginTop: '50px',
  ...EditVariantCancelButtonStyle
}
export const PlaceholderStyle: React.CSSProperties = {
  display: 'inline-flex',
  flexWrap: 'nowrap',
  whiteSpace: 'nowrap'
}

export const PlaceholderSpanStyle = {
  ...PlaceholderStyle,
  ...SelectTypographyStyle
}

export const customSnackbarStyles = {
  borderRadius: '10px',
  width: '100%',
  height: '40px'
}

export const TextFieldStyle = (width: string) => ({
  '& .MuiFormControl-root, & .MuiTextField-root, & .MuiInputBase-root, & .MuiOutlinedInput-root': {
    height: '48px',
    width: width ?? '',
    border: 1,
    borderRadius: '10px',
    borderColor: CustomPalette.SecondaryNightBlue200,
    margin: 'auto 0',
    backgroundColor: 'transparent',
    '&:hover': {
      borderColor: CustomPalette.SecondaryNightBlue900
    },
    '&:focus-within': {
      borderColor: CustomPalette.SecondaryNightBlue900,
      boxShadow: `0px 0px 0px 2px ${CustomPalette.PilotBlueShadow}`
    }
  },
  '& .MuiInputBase-input, & .MuiOutlinedInput-input': {
    '::placeholder': {
      color: CustomPalette.SecondaryNightBlue600,
      paddingLeft: '2px',
      fontSize: '16px',
      fontWeight: '400'
    },
    '::-webkit-input-placeholder': {
      color: CustomPalette.SecondaryNightBlue600,
      paddingLeft: '2px',
      fontSize: '16px',
      fontWeight: '400'
    },
    ':-ms-input-placeholder': {
      color: CustomPalette.SecondaryNightBlue600,
      paddingLeft: '2px',
      fontSize: '16px',
      fontWeight: '400'
    }
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
})
