// material-ui
import { Grid } from '@mui/material'

// project import
import { ToolBar } from '@/common/components/ToolBar/ToolBar'
import { CardList } from '@/features/my/trainings/components/List/CardList'
import { TrainingParticipationData } from '@/features/my/types'
import { usePageVisited } from '@/hooks'
import { useTrainingsGetCurrentUserTrainingParticipationsTenantsTenantIdMeTrainingParticipationsGetQuery } from '@/services/api/tenant/trainings'
import { RootState, useTypedSelector } from '@/store'
import { useTranslation } from 'react-i18next'
import { TrainingSearchFormContext } from '../components/TrainingSearch/TrainingSearchFormContext'

const Trainings = (): JSX.Element => {
  const { t } = useTranslation('general')
  const accessedTenant = useTypedSelector((state: RootState) => state.authSlice.tenant)
  const { data: trainingsApiResponse } =
    useTrainingsGetCurrentUserTrainingParticipationsTenantsTenantIdMeTrainingParticipationsGetQuery({
      tenantId: accessedTenant?.id
    })
  usePageVisited('My Trainings')
  const trainings: TrainingParticipationData[] = trainingsApiResponse
    ? trainingsApiResponse.map((trainingParticipants) => ({
        trainingParticipantsId: trainingParticipants.id,
        trainingUserId: trainingParticipants.training_user_id,
        trainingCycleId: trainingParticipants.training_cycle_id,
        trainingRecurringInterval: trainingParticipants.training_recurring_interval,
        trainingCode: trainingParticipants.training_code,
        description: trainingParticipants.training_description,
        startedAt: trainingParticipants.training_start_date,
        completedAt: trainingParticipants.training_end_date,
        trainingStartDate: trainingParticipants.training_start_date,
        trainingEndDate: trainingParticipants.training_end_date,
        button:
          trainingParticipants.training_status === 'completed'
            ? t('view_training')
            : trainingParticipants?.courseplay_url
            ? t('continue_training')
            : t('start_training'),
        courseId: trainingParticipants?.course_id,
        status: trainingParticipants.training_status,
        title: trainingParticipants.training_internal_name,
        time: '',
        participantTotal: 0,
        lastActive: '2021-09-08', // TODO:  We miss this field in the API response
        duration: trainingParticipants.course_duration_in_minutes,
        repeat: trainingParticipants.repeat,
        coursePlayUrl: trainingParticipants.courseplay_url
      }))
    : []

  return (
    <Grid>
      <Grid sx={{ ml: 2, mb: 2 }}>
        <ToolBar title={t('myTrainings')} />
      </Grid>
      <Grid md={12} sx={{ mr: 4 }}>
        <Grid container alignItems="center" justifyContent="space-between">
          <TrainingSearchFormContext>
            <CardList list={trainings}></CardList>
          </TrainingSearchFormContext>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Trainings
