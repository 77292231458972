import { Grid, Link, Typography } from '@mui/material'
import FontFaceLoader from '../../theme/CustomFont'

export const Footer = () => (
  <footer>
    <FontFaceLoader />
    <Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="center"
      sx={{ p: '0 17px', width: '200' }}>
      <Grid item sx={{ p: '0 20px' }}>
        <Typography>
          <Link
            href="https://lawpilots.com/en/terms-of-service/"
            sx={{ color: '#5B5C79', fontSize: '12px', textDecoration: 'underline' }}>
            Terms and Conditions
          </Link>
        </Typography>
      </Grid>
      <Grid item sx={{ padding: '0 17px' }}>
        <Typography>
          <Link
            href="https://lawpilots.com/en/data-protection/"
            sx={{ color: '#5B5C79', fontSize: '12px', textDecoration: 'underline' }}>
            Privacy Policy
          </Link>
        </Typography>
      </Grid>
      <Grid item sx={{ padding: '0 17px' }}>
        <Typography>
          <Link
            href="https://lawpilots.com/en/imprint/"
            sx={{ color: '#5B5C79', fontSize: '12px', textDecoration: 'underline' }}>
            CA Privacy Notice
          </Link>
        </Typography>
      </Grid>
    </Grid>
  </footer>
)
