import { createSlice } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { CourseState } from './types'

const initialState: CourseState = {
  openCreateCourseMenu: false,
  openEditCourseMenu: false,
  openCreateVariantMenu: false,
  expandDialog: false,
  isCourseCreated: false,
  isCourseEdited: false,
  searchValue: undefined,
  variantPreviewEditMode: false,
  newVariantValues: undefined,
  variantCreated: false,
  variantNotCreated: false,
  createUpdateVariantError: '',
  isSyncingLMDhVariants: false,
  syncLMDhVariantsMessage: undefined
}

export const courseSlice = createSlice({
  name: 'courseSlice',
  initialState,
  reducers: {
    openCreateCourseMenu(state, action) {
      state.openCreateCourseMenu = action.payload
    },
    openEditCourseMenu(state, action) {
      state.openEditCourseMenu = action.payload
    },
    setCourseMenu(state, action) {
      state.setCourseMenu = action.payload
    },
    setExpandDialog(state, action) {
      state.expandDialog = action.payload
    },
    setIsCourseCreated(state, action) {
      state.isCourseCreated = action.payload
    },
    setIsCourseEdited(state, action) {
      state.isCourseEdited = action.payload
    },
    setSearchValue(state, action) {
      state.searchValue = action.payload
    },
    setVariantPreviewEditMode(state, action) {
      state.variantPreviewEditMode = action.payload
    },
    setNewVariantValues(state, action) {
      state.newVariantValues = action.payload
    },
    setVariantCreated(state, action) {
      state.variantCreated = action.payload
    },
    setVariantNotCreated(state, action) {
      state.variantNotCreated = action.payload.variantNotCreated
      state.createUpdateVariantError = action.payload.createUpdateVariantError
    },
    openCreateVariantMenu(state, action) {
      state.openCreateVariantMenu = action.payload
    },
    updateCreatedOrUpdatedCourseVariant(state, action) {
      state.updateCreatedOrUpdatedCourseVariant = action.payload
    },
    setIsSyncingLMDhVariants(state, action) {
      state.isSyncingLMDhVariants = action.payload
    },
    setSyncLMDhVariantsMessage(state, action) {
      state.syncLMDhVariantsMessage = action.payload
    }
  }
})

export const courseReducer = persistReducer(
  {
    key: 'rtk:course',
    storage,
    whitelist: ['']
  },
  courseSlice.reducer
)
