import styled from '@emotion/styled'
import { SvgIconProps } from '@mui/material'

const SvgLogoIcon = styled('svg')(() => ({
  root: {
    width: '100%',
    height: '100%'
  }
}))

// the color can be replace from "black" to #463ed7

export const CompanyLogo = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgLogoIcon width="70%" role="img" viewBox="0 0 1212.53 250" data-testid="company-logo" {...props}>
      <path
        d="M27.77,173.9V64.8H0v102.93c0,36.2,8.64,43.61,49.78,41.14v-16.25c-17.28-.21-22.01-5.14-22.01-18.72Z"
        fill="#463ed7"
      />
      <path
        d="M170.98,170.4v-32.91c0-24.68-19.13-38.88-49.98-38.88-33.12,0-53.48,14.81-53.48,36h27.77c0-11.11,9.05-19.75,25.09-19.75,14.6,0,22.83,7.82,22.63,17.28,0,6.99-5.14,10.49-19.95,13.78l-17.07,3.7c-29,6.17-40.73,15.43-40.73,32.91s15.63,29.62,39.9,29.62c21.39,0,38.46-12.14,43.61-29.21,1.23,21.19,13.37,28.8,40.52,25.92v-16.25c-15.43,0-18.31-6.99-18.31-22.21Zm-27.97-9.67c0,19.33-13.78,35.17-30.85,35.17-11.93,0-18.92-4.94-18.92-13.58,0-8.02,5.55-12.75,19.54-16.25l12.75-3.29c9.26-2.26,14.6-5.14,17.48-8.84v6.79Z"
        fill="#463ed7"
      />
      <polygon
        points="311.2 180.27 285.69 101.91 258.75 101.91 233.45 180.27 210.21 101.91 182.03 101.91 218.84 208.86 246.2 208.86 272.32 131.94 298.03 208.86 325.6 208.86 362.41 101.91 334.03 101.91 311.2 180.27"
        fill="#463ed7"
      />
      <path
        d="M437.24,98.61c-15.02,0-30.03,8.43-37.02,20.98v-17.69h-27.77V250h27.77v-58.83c6.99,12.55,22.01,20.98,37.02,20.98,29.62,0,50.19-22.83,50.19-56.36s-20.57-56.77-50.19-57.18Zm-7.82,97.29c-18.1,0-30.44-16.66-30.44-40.31s12.34-40.73,30.44-40.73,30.03,16.66,30.03,40.73-11.93,40.31-30.03,40.31Z"
        fill="#463ed7"
      />
      <path
        d="M515.68,63.71c-8.56,0-14.84,5.76-14.84,13.67s6.29,13.67,14.84,13.67,14.67-5.76,14.67-13.67-6.29-13.67-14.67-13.67Z"
        fill="#463ed7"
      />
      <rect x="501.83" y="101.91" width="27.77" height="106.96" fill="#463ed7" />
      <path
        d="M578.45,173.9V64.8h-27.77v102.93c0,36.2,8.64,43.61,49.78,41.14v-16.25c-17.28-.21-22.01-5.14-22.01-18.72Z"
        fill="#463ed7"
      />
      <path
        d="M664.31,98.61c-36.2,0-60.06,22.42-60.06,56.57s23.86,56.97,60.06,56.97,59.85-22.63,59.85-56.97-23.45-56.57-59.85-56.57Zm0,97.29c-19.33,0-31.88-16.45-31.88-40.73s12.55-40.32,31.88-40.32,31.68,16.25,31.68,40.32-12.55,40.73-31.68,40.73Z"
        fill="#463ed7"
      />
      <path
        d="M775.83,167.11v-48.75h29.62v-16.45h-29.62v-37.11h-27.77v37.11h-20.77v16.45h20.77v47.93c-.62,35.17,13.16,46.07,60.27,42.37v-16.87c-25.3,.82-32.5-2.06-32.5-24.68Z"
        fill="#463ed7"
      />
      <path
        d="M892.31,147.36l-26.53-6.99c-10.9-2.88-16.04-7.4-16.04-14.4,0-8.43,7.4-13.16,20.98-13.16,14.4,0,22.83,6.58,23.86,18.31h26.53c-1.44-19.95-19.75-32.5-49.16-32.5-32.09,0-50.19,11.52-50.19,30.44,0,15.43,11.31,25.5,35.79,32.09l18.51,4.94c13.99,3.7,20.36,9.05,20.36,17.07,0,9.26-8.64,14.81-23.86,14.81-17.69,0-27.77-7.82-27.97-21.8h-27.56c.62,21.8,20.98,36,54.71,36s52.66-12.34,52.66-32.29c0-16.45-10.08-26.74-32.09-32.5Z"
        fill="#463ed7"
      />
      <path
        d="M1130.66,0c-5.57,1.87-10.99,4.06-16.22,6.57,10.39,12.16,29.22,38.31,39.11,77.41,3.96,15.58,6.48,33.23,6.48,52.86,0,3.81-.39,7.46-1.05,10.94,.16-3.63,.13-7.41-.09-11.4-1.36-24.48-9.93-45.78-21.19-63.59-14.77-23.37-34.14-40.68-47.93-51.18-4.57,3.42-8.93,7.15-13.03,11.11,9.3,6.88,22.4,17.84,34.47,32.28,15.63,18.67,29.49,43.17,31.11,72.31,.59,10.61-.4,19.42-2.99,26.6,1.05-8.38,.59-17.84-1.41-28.35-6.03-31.71-29.76-53.77-52.96-68.19-9.22-5.72-18.35-10.24-26.26-13.68-3.23,4.6-6.21,9.38-8.9,14.37,4.92,2.08,10.47,4.64,16.23,7.74,23.07,12.28,49.82,32.66,55.57,62.88,2.46,12.91,2.21,23.5-.71,31.62-.85-25.07-16.15-46.42-44.36-61.87-11.55-6.35-23.04-10.45-31.15-12.91-2.49-.74-4.67-1.33-6.42-1.79-1.65,5.25-2.99,10.63-4.02,16.13,.73,.19,1.53,.4,2.42,.65,18,5.04,66.97,22.43,66.97,62.03,0,15.17-11.43,19.61-35.86,19.61h-72.04v16.61h107.82c39.73,0,72.2-32.38,72.28-72.1-.06-69.25-28.69-115.3-45.86-136.64Z"
        fill="#463ed7"
      />
      <path
        d="M1194.63,175.49c-10.44,0-18.11,7.03-18.11,16.69s7.67,16.69,18.11,16.69,17.9-7.03,17.9-16.69-7.67-16.69-17.9-16.69Z"
        fill="#463ed7"
      />
    </SvgLogoIcon>
  )
}

// the color can be replace from "black" to #463ed7
