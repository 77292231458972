import courses from './routes'

export {
  CourseDetailsTabs,
  CourseSearch,
  CourseSearchFormContext,
  CreateCourse,
  ELearningContentSearch,
  ELearningContentSearchFormContext,
  ProductSearch,
  ProductSearchFormContext
} from './components'
export {
  useProductSearchFormContext,
  useSearchCourses,
  useSearchProducts,
  useSearchELearningContent,
  useELearningContentSearchFormContext
} from './hooks'
export { courseReducer, courseSlice } from './slice'
export type {
  Course,
  CourseSearchArgs,
  CourseSearchFormValues,
  ELearningContent,
  ELearningContentSearchArgs,
  ELearningContentSearchFormValues,
  LanguageSearchArgs,
  LanguageSearchFormValues,
  Product,
  ProductSearchArgs,
  ProductSearchFormValues,
  Variant
} from './types'

export default [...courses] as const
