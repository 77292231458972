import CustomPalette from '@/theme/CustomPalette'

export const certificateTitleStyles = {
  ml: '12px',
  mb: 1.5,
  fontSize: 12,
  fontFamily: 'BeausiteClassic-Regular',
  color: CustomPalette.NightBlue400
}

export const trainingInternalNameStyles = {
  mb: 1,
  fontSize: 20,
  fontWeight: '800px',
  fontFamily: 'BeausiteClassic-Regular',
  color: CustomPalette.NightBlue400
}

export const obtainedOnTitleStyles = {
  fontSize: 14,
  fontFamily: 'BeausiteClassic-Regular',
  color: CustomPalette.NightBlue400
}

export const certViewButtonStyles = {
  mr: 1.5,
  color: CustomPalette.PilotBlue,
  fontFamily: 'BeausiteClassic-Regular',
  fontSize: '16px'
}

export const certDownloadButtonStyles = {
  mr: 1.5,
  color: CustomPalette.PilotBlue,
  fontFamily: 'BeausiteClassic-Regular',
  fontSize: '16px'
}

export const alertStyles = {
  mb: 2,
  position: 'absolute',
  bottom: '40px',
  left: '50%',
  transform: 'translate(-50%, -40px)',
  width: '360px'
}
