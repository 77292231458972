import type { Route } from '../../core'
import type Home from './Home'

/**
 * Homepage route.
 */
export default {
  path: '/',
  query: '',
  authorize: ['customer_success', 'customer_manager', 'participant'],
  component: () => import(/* webpackChunkName: "home" */ './Home'),
  response: () => ({
    title: ''
  })
} as unknown as Route<typeof Home>
