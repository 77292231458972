import '@/locales/i18n'
import { store } from '@/store'
import { styled } from '@mui/system'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import history from 'history/browser'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { App, Login } from './common'
import { config } from './core/config'
import { setupTenant } from './core/tenant'
import { TenantData } from './core/types'
import reportWebVitals from './reportWebVitals'

const rootUrl = new URL(document?.URL)
const tenant = (await setupTenant(rootUrl)) as TenantData

// Render the top-level React component
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!
const root = createRoot(container)

config.app.env !== 'local' &&
  Sentry.init({
    environment: 'production',
    dsn: 'https://2e9c055f63984d3aac98ed33ee94ddea@o455686.ingest.sentry.io/4504565293514752',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  })

const BackgroundContainer = styled('div')({
  backgroundColor: '#F8F8FA',
  minHeight: '100vh'
})

root.render(
  <Provider store={store}>
    {!tenant.id ? (
      <BackgroundContainer>
        <Login />
      </BackgroundContainer>
    ) : (
      <App history={history} tenant={tenant} />
    )}
  </Provider>
)

// To start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
