import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const BookmarkBook = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon style={{ fontSize: '20px' }} role="img" viewBox="0 0 18 18" {...props}>
      <path
        d="M3 14.25V3.75C3 2.92157 3.67157 2.25 4.5 2.25H14.55C14.7985 2.25 15 2.45147 15 2.7V12.5357"
        stroke="#6F708E"
        stroke-width="1.2"
        stroke-linecap="round"
        fill="none"
      />
      <path
        d="M6 2.25V8.25L7.875 7.05L9.75 8.25V2.25"
        stroke="#6F708E"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <path d="M4.5 12.75H15" stroke="#6F708E" stroke-width="1.2" stroke-linecap="round" fill="none" />
      <path d="M4.5 15.75H15" stroke="#6F708E" stroke-width="1.2" stroke-linecap="round" fill="none" />
      <path
        d="M4.5 15.75C3.67157 15.75 3 15.0784 3 14.25C3 13.4216 3.67157 12.75 4.5 12.75"
        stroke="#6F708E"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
    </SvgIcon>
  )
}
