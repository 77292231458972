import { FavoriteTrainingClose, Participants, ParticipantsClose, SettingsIconClose } from '../../../../icons'
import { Administration } from '../../../../icons/Administration'
import { BookmarkBook } from '../../../../icons/BookmarkBook'
import { BookmarkBookClose } from '../../../../icons/BookmarkBookClose'
import { CertificateIconClose } from '../../../../icons/CertificateIconClose'
import { CertificateIconOpen } from '../../../../icons/CertificateIconOpen'
import { FavoriteTraining } from '../../../../icons/FavoriteTraining'
import { HeadSetClose } from '../../../../icons/HeadSetClose'
import { HeadSetOpen } from '../../../../icons/HeadSetOpen'
import { Home } from '../../../../icons/Home'
import { HomeClose } from '../../../../icons/HomeClose'
import { SettingsIcon } from '../../../../icons/SettingsIcon'

import { SearchTenantIcon } from '@/icons/SearchTenantIcon'
import { AppBarProps, ListItemIcon } from '@mui/material'

type NavItemProps = AppBarProps & {
  isOpen?: boolean
  icon: string
  isSidebarOpen: boolean
  isTenantSearch?: boolean
}

const defaultIcon = { open: Home, close: HomeClose }

const icons: Record<string, { open: React.ComponentType; close: React.ComponentType }> = {
  Home: { open: Home, close: HomeClose },
  BookmarkBook: { open: BookmarkBook, close: BookmarkBookClose },
  Administration: { open: Administration, close: Administration },
  SettingsIcon: { open: SettingsIcon, close: SettingsIconClose },
  FavoriteTraining: { open: FavoriteTraining, close: FavoriteTrainingClose },
  HeadSetIcon: { open: HeadSetOpen, close: HeadSetClose },
  SearchTenantIcon: { open: SearchTenantIcon, close: SearchTenantIcon },
  CertificateIcon: { open: CertificateIconOpen, close: CertificateIconClose },
  ParticipantsIcon: { open: Participants, close: ParticipantsClose },
  default: defaultIcon
}

export const NavItem = (props: NavItemProps): JSX.Element => {
  const { isOpen, icon, isSidebarOpen, isTenantSearch } = props
  const IconComponent = icons[icon] || icons.default
  const Icon = isSidebarOpen ? IconComponent.open : IconComponent.close

  return (
    <ListItemIcon
      sx={{
        minWidth: 0,
        mr: isOpen && !isTenantSearch ? 3 : 'auto',
        justifyContent: isTenantSearch ? 'end' : 'center'
      }}>
      <Icon />
    </ListItemIcon>
  )
}
