import { SvgIcon, SvgIconProps } from '@mui/material'
import * as React from 'react'

export const Download = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon role="img" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </SvgIcon>
  )
}
