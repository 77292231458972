import tenants from './routes'

export {
  CreateTenant,
  PartnerSearch,
  PartnerSearchFormContext,
  TenantDetailsTabs,
  TenantSearch,
  TenantSearchBar,
  TenantSearchFormContext
} from './components'
export { usePartnerSearchFormContext, useSearchPartners, useSearchTenants } from './hooks'
export { TenantMenu } from './menus'
export { tenantReducer, tenantSlice } from './slice'
export type {
  Manager,
  MenusNavItem,
  Partner,
  PartnerSearchArgs,
  PartnerSearchFormValues,
  TabsNavItem,
  Tenant,
  TenantSearchArgs,
  TenantSearchFormValues
} from './types'

export default [...tenants] as const
