import CustomPalette from '@/theme/CustomPalette'

export const CourseSearchStyle = {
  '& .MuiFormControl-root, & .MuiTextField-root': {
    margin: 'auto 0'
  },
  '& .MuiFormControl-root, & .MuiTextField-root, & .MuiInputBase-root, & .MuiOutlinedInput-root': {
    width: 400,
    height: 42,
    border: 1,
    borderRadius: '10px',
    borderColor: CustomPalette.SecondaryNightBlue200,
    margin: 'auto 0'
  },
  '& .MuiInputAdornment-root': { color: CustomPalette.SecondaryNightBlue900, height: '18px', width: '18px' },
  '& .MuiInputBase-input, & .MuiOutlinedInput-input': {
    '::placeholder': {
      color: CustomPalette.SecondaryNightBlue600,
      paddingLeft: '2px',
      fontSize: '16px',
      fontWeight: '400'
    },
    '::-webkit-input-placeholder': {
      color: CustomPalette.SecondaryNightBlue600,
      paddingLeft: '2px',
      fontSize: '16px',
      fontWeight: '400'
    },
    ':-ms-input-placeholder': {
      color: CustomPalette.SecondaryNightBlue600,
      paddingLeft: '2px',
      fontSize: '16px',
      fontWeight: '400'
    }
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
}
