import type { ThemeOptions } from '@mui/material/styles'

/**
 * Style overrides for Material UI components.
 */
export const components: ThemeOptions['components'] = {
  // https://github.com/mui-org/material-ui/tree/next/packages/material-ui/src/Button
  MuiButton: {
    styleOverrides: {
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none'
        }
      }
    }
  },
  // https://github.com/mui-org/material-ui/tree/next/packages/material-ui/src/ButtonGroup
  MuiButtonGroup: {
    styleOverrides: {
      root: {
        boxShadow: 'none'
      }
    }
  },
  MuiDialog: {
    styleOverrides: {
      root: {
        '& .MuiDialog-paper': {
          borderRadius: '20px'
        }
      }
    }
  }
}
