import { SvgIcon, SvgIconProps } from '@mui/material'
import * as React from 'react'

interface CheckIconProps extends SvgIconProps {
  strokeColor: string
}

export const CheckIcon = (props: CheckIconProps): JSX.Element => {
  const { strokeColor, ...restProps } = props

  return (
    <SvgIcon role="img" viewBox="0 0 18 18" {...restProps}>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1636_2299)">
          <path
            d="M5.25 9.375L7.5 11.625L12.75 6.375"
            stroke={strokeColor}
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
            stroke={strokeColor}
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1636_2299">
            <rect width="18" height="18" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}
