import { CompanyLogo } from '@/common/components'
import { config as appConfig } from '@/core'
import { setupTenantByEmail } from '@/core/tenant'
import * as amplitude from '@amplitude/analytics-browser'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined'
import MailOutlinedIcon from '@mui/icons-material/MailOutlined'
import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography
} from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import FontFaceLoader from '../../theme/CustomFont'
import { Footer } from '../components/Footer'
import {
  ArrowForwardIconStyle,
  ButtonStyle,
  ContainerBoxStyle,
  FooterBoxStyle,
  FormControlStyle,
  InputLabelStyle,
  InputStyle,
  MailIconStyle,
  TypographyEmailStyle,
  TypographyH4Style,
  TypographySpanStyle,
  WrapperBoxStyle,
  errorMixin
} from './LoginStyle'
import { createTenantUrl, useSessionCheck } from './hooks/useSessionCheck'

export const Login = (): JSX.Element => {
  const { t } = useTranslation('general')
  const appUrl = appConfig.app.host
  const [email, setEmail] = React.useState('')
  const [errors, setErrors] = React.useState('')
  const [sessionActive, setSessionActive] = React.useState<boolean>(true)

  const handleSubmit = async (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault()
    setErrors('')
    const tenant = await setupTenantByEmail(email)
    amplitude.setUserId(email)
    if ('subdomain' in tenant) {
      window.location.assign(createTenantUrl(tenant.subdomain, email, appUrl))
    } else if ('statusText' in tenant) {
      setErrors(tenant.statusText)
    }
  }

  useSessionCheck(appUrl, setSessionActive)

  if (sessionActive) {
    return <></>
  }

  return (
    <Box sx={WrapperBoxStyle}>
      <Box sx={ContainerBoxStyle}>
        <Container maxWidth="sm">
          <FontFaceLoader />
          <Grid container direction="column" justifyContent="center" alignItems="left" sx={{ maxWidth: '490px' }}>
            <CompanyLogo width={200} />
            <Typography variant="h4" sx={TypographyH4Style}>
              Welcome <span style={TypographySpanStyle}>to</span>
              <span style={{ ...TypographySpanStyle, whiteSpace: 'nowrap' }}> lawpilots learning platform!</span>
            </Typography>
            <Typography sx={TypographyEmailStyle}>{t('login_description_business_email')}</Typography>
            <form onSubmit={handleSubmit}>
              <FormControl variant="outlined" error={!!errors} fullWidth sx={FormControlStyle(errors)}>
                <InputLabel htmlFor="email" sx={InputLabelStyle} required>
                  {t('login_enter_email_label')}
                </InputLabel>
                <OutlinedInput
                  inputProps={{ 'data-testid': 'name-input' }}
                  id="email"
                  value={email}
                  sx={InputStyle}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={t('login_enter_email') || undefined}
                  css={errors && errorMixin}
                  error={!!errors}
                  startAdornment={
                    <InputAdornment position="start">
                      <MailOutlinedIcon fontSize="small" sx={MailIconStyle} />
                    </InputAdornment>
                  }
                />
                <FormHelperText data-testid="helperText">{errors ? errors : ''}</FormHelperText>
              </FormControl>

              <Button
                onClick={handleSubmit}
                endIcon={<ArrowForwardOutlinedIcon sx={ArrowForwardIconStyle} />}
                sx={ButtonStyle}>
                {t('login_continue')}
              </Button>
            </form>
          </Grid>
        </Container>
      </Box>
      <Box sx={FooterBoxStyle}>
        <Footer />
      </Box>
    </Box>
  )
}
