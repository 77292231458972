import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const HomeClose = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon style={{ fontSize: '28px' }} role="img" viewBox="0 0 24 24" {...props}>
      <path
        d="M2 8L11.7317 3.13416C11.9006 3.04971 12.0994 3.0497 12.2683 3.13416L22 8"
        stroke="#151830"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <path
        d="M20 11V19C20 20.1046 19.1046 21 18 21H6C4.89543 21 4 20.1046 4 19V11"
        stroke="#151830"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
    </SvgIcon>
  )
}
