import { AppBarProps, Box, Container } from '@mui/material'
import React from 'react'

type MainProps = AppBarProps & {
  children: React.ReactNode
}

export const Main = (props: MainProps): JSX.Element => {
  const { children } = props

  return (
    <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
      <Container
        maxWidth={false}
        sx={{
          ...{ px: { xs: 0, sm: 2 } },
          position: 'relative',
          minHeight: 'calc(100vh - 110px)',
          display: 'flex',
          flexDirection: 'column'
        }}>
        {children}
      </Container>
    </Box>
  )
}
