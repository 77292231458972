export const dialogStyles = {
  '& .MuiPaper-root': {
    borderRadius: '20px',
    maxWidth: '360px'
  }
}

export const dialogContentStyles = {
  padding: '24px',
  width: 'auto'
}

export const bodyStyles = {
  marginBottom: '40px',
  marginTop: '16px'
}

export const goToLoginButtonStyles = {
  backgroundColor: '#463ED7',
  border: '1px solid #463ED7',
  borderRadius: '20px',
  color: '#FFFFFF',
  display: 'block',
  padding: '6px 22px',
  textAlign: 'center',
  width: '100%',
  '&:hover': {
    backgroundColor: '#F8F8FA',
    borderColor: '#463ED7',
    color: '#463ED7',
    transition: '0.5s ease'
  }
}
