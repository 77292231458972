import { Suspense, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppBarProps, Box, Button, CircularProgress, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import { MenusNavItem } from '../../../features/tenant'
import { useLoadViews } from '../../../hooks'
import FontFaceLoader from '../../../theme/CustomFont'
import CustomPalette from '../../../theme/CustomPalette'
import CustomButton from '../CustomButton/CustomButton'

type ToolBarProps = AppBarProps & {
  customButtonStyles?: React.CSSProperties
  withCustomButton?: boolean
  buttonOnly?: boolean
  title?: string
  description?: string
  actionLabel?: string
  customButtonLabel?: string
  altCustomButtonLabel?: string
  menus?: MenusNavItem[]
  openAction?: () => void
  closeAction?: () => void
}

export const ToolBar = ({
  customButtonStyles,
  withCustomButton = false,
  buttonOnly = false,
  title,
  description,
  actionLabel,
  customButtonLabel,
  altCustomButtonLabel,
  menus,
  openAction,
  closeAction,
  ...other
}: ToolBarProps): JSX.Element => {
  const { t, i18n } = useTranslation('general')
  const [Menus, setMenus] = useState<Array<JSX.Element>>([])

  useEffect(() => {
    const loadViews = async () => {
      return await useLoadViews({
        views: menus,
        action: closeAction,
        ...other
      })
    }

    loadViews().then(setMenus)
  }, [menus, i18n.language])

  return (
    <Suspense fallback={<CircularProgress />} key={i18n.language}>
      <FontFaceLoader />
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          m: -1,
          overflow: 'auto'
        }}
        {...other}>
        {!buttonOnly && (
          <Box sx={{ m: 1 }}>
            <Typography
              sx={{
                color: CustomPalette.NightBlue200,
                fontFamily: 'BeausiteClassic-Regular',
                fontSize: '24px',
                fontWeight: '600',
                ml: '3px'
              }}
              variant="h3">
              {title}
            </Typography>
            <Typography sx={{ m: 1 }} variant="h5">
              {description}
            </Typography>
          </Box>
        )}
        {actionLabel && (
          <Box sx={{ m: 1 }}>
            <Button sx={{ m: 1 }} color="primary" variant="contained" onClick={openAction}>
              {actionLabel}
            </Button>
          </Box>
        )}
        {withCustomButton && (
          <CustomButton
            startIcon={
              <AddIcon
                sx={{
                  transition: '1s ease',
                  '&:hover': {
                    transition: ' 0.5s ease'
                  }
                }}
              />
            }
            styles={customButtonStyles}
            type="submit"
            onClick={openAction}>
            {customButtonLabel ?? altCustomButtonLabel}
          </CustomButton>
        )}
        {Menus}
      </Box>
    </Suspense>
  )
}
