import styled from '@emotion/styled'
import { SvgIconProps } from '@mui/material'

const SvgLogoIcon = styled('svg')(() => ({
  root: {
    width: '100%',
    height: '100%'
  }
}))

// the color can be replace from "black" to #463ed7

export const CompanyLogoSmall = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgLogoIcon width="76%" role="img" viewBox="0 0 24 23" data-testid="company-logo-small" {...props}>
      <g clip-path="url(#clip0_1866_14349)">
        <path
          d="M14.908 0C14.2894 0.205927 13.6875 0.447094 13.1067 0.723499C14.2605 2.06258 16.3517 4.94226 17.45 9.24801C17.8898 10.9637 18.1696 12.9074 18.1696 15.069C18.1696 15.4886 18.1263 15.8905 18.053 16.2738C18.0708 15.874 18.0675 15.4578 18.043 15.0184C17.892 12.3226 16.9403 9.97702 15.6898 8.01575C14.0495 5.44221 11.8984 3.536 10.3669 2.37973C9.85942 2.75634 9.37523 3.1671 8.9199 3.60318C9.95271 4.36082 11.4075 5.56775 12.748 7.1579C14.4837 9.21387 16.0229 11.9119 16.2029 15.1208C16.2684 16.2892 16.1584 17.2594 15.8708 18.05C15.9874 17.1272 15.9363 16.0855 15.7142 14.9281C15.0446 11.4361 12.4092 9.00685 9.83277 7.41889C8.80885 6.789 7.79492 6.29125 6.91648 5.91243C6.55777 6.41899 6.22683 6.94537 5.92809 7.49488C6.47448 7.72393 7.09083 8.00584 7.73051 8.34722C10.2925 9.69951 13.2632 11.9438 13.9018 15.2717C14.175 16.6933 14.1472 17.8595 13.823 18.7537C13.7286 15.993 12.0294 13.6419 8.89658 11.9405C7.6139 11.2412 6.33788 10.7897 5.43723 10.5188C5.1607 10.4373 4.91861 10.3724 4.72426 10.3217C4.54102 10.8998 4.39221 11.4923 4.27782 12.098C4.35889 12.1189 4.44773 12.142 4.54657 12.1695C6.54555 12.7246 11.9839 14.6396 11.9839 19.0004C11.9839 20.6709 10.7145 21.1599 8.00148 21.1599H0V22.989H11.9739C16.3861 22.989 19.992 19.4233 20.0009 15.0492C19.9943 7.4233 16.8148 2.3522 14.908 0.00220243V0Z"
          fill="#463ED7"
        />
        <path
          d="M22.011 19.3252C20.8515 19.3252 19.9998 20.0993 19.9998 21.1631C19.9998 22.2269 20.8515 23.0011 22.011 23.0011C23.1704 23.0011 23.9988 22.2269 23.9988 21.1631C23.9988 20.0993 23.147 19.3252 22.011 19.3252Z"
          fill="#463ED7"
        />
      </g>
      <defs>
        <clipPath id="clip0_1866_14349">
          <rect width="24" height="23" fill="white" />
        </clipPath>
      </defs>
    </SvgLogoIcon>
  )
}

// the color can be replace from "black" to #463ed7
