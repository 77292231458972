import { Route } from '@/core/router.types'
import type Courses from './Courses'
import type Details from './Details/Details'

/**
 * Courses page routes.
 */
export default [
  {
    menu: { label: 'courseCatalogue', icon: 'BookmarkBook', appendTenantSearch: true },
    path: '/courses',
    authorize: ['customer_success'],
    sidebarTab: true,
    component: () => import(/* webpackChunkName: "Courses" */ /* webpackMode: "eager" */ './Courses'),
    response: (data: any) => ({
      title: 'Courses',
      description: 'Use the search to start support tasks for courses or create a new course.',
      props: data
    })
  } as unknown as Route<typeof Courses>,
  {
    path: '/courses/:courseId',
    query: '',
    authorize: ['customer_success'],
    component: () => import(/* webpackChunkName: "Details" */ './Details/Details'),
    response: (data: any) => ({
      title: 'Courses',
      description: 'Use the search to start support tasks for courses or create a new course.',
      props: data
    })
  } as unknown as Route<typeof Details>
] as const
