import { Chip } from '@mui/material'

import { UserProfileData } from '../../../../features/auth/types'
import { AvatarIcon } from '../../../../icons/AvatarIcon'
import { useTypedSelector } from '../../../../store'
import CustomPalette from '../../../../theme/CustomPalette'

type UserProfileProps = {
  isOpen?: boolean
}

export const UserProfile: React.FC<UserProfileProps> = ({ isOpen }) => {
  const user = useTypedSelector((state) => state.authSlice.user)

  const userMapped = (data: any): UserProfileData => {
    return {
      given_name: data.token.given_name,
      family_name: data.token.family_name
    }
  }

  if (!user) {
    return <></>
  }

  return (
    <Chip
      icon={<AvatarIcon sx={{ fontSize: '18px', color: '#6F708E !important' }} />}
      label={isOpen ? getFullName(userMapped(user).given_name, userMapped(user).family_name) : null}
      sx={{
        color: '#151830',
        fontFamily: 'BeausiteClassic-Regular',
        fontSize: '16px',
        fontWeight: '600px',
        ml: 1.5,
        mb: 1.5,
        mt: 1.5,
        backgroundColor: CustomPalette.SecondaryCloud
      }}
    />
  )
}

const getFullName = (givenName: string, familyName: string): string => {
  return `${givenName} ${familyName}`.trim()
}
